export const hotelData = [
  {
    hotel_id: "20199",
    last_updated: "2023-11-05T18:53:16.187Z",
    name: "Royal Mara Safari Lodge",
    country: "KE",
    region: "Narok",
    city: "Narok",
    city_Id: "10828",
  },
  {
    hotel_id: "20290",
    last_updated: "2023-11-05T18:53:16.187Z",
    name: "Grand Royal Swiss Hotel",
    country: "KE",
    region: "Kisumu",
    city: "Kisumu",
    city_Id: "7410",
  },
  {
    hotel_id: "21339",
    last_updated: "2023-11-05T18:53:16.189Z",
    name: "The Panari Resort Nyahururu",
    country: "KE",
    region: "Laikipia",
    city: "Laikipia",
    city_Id: "8071",
  },
  {
    hotel_id: "21346",
    last_updated: "2023-11-05T18:53:16.189Z",
    name: "Sportsview Hotel Kasarani",
    country: "KE",
    region: "African Great Lakes region",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "21349",
    last_updated: "2023-11-05T18:53:16.189Z",
    name: "The ONE",
    country: "KE",
    region: "Kilifi County",
    city: "Kilifi",
    city_Id: "395",
  },
  {
    hotel_id: "21378",
    last_updated: "2023-11-05T18:53:16.191Z",
    name: "Reef Hotel Mombasa",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "21380",
    last_updated: "2023-11-05T18:53:16.191Z",
    name: "Leopard Beach Resort and Spa",
    country: "KE",
    region: "Kwale",
    city: "Kwale",
    city_Id: "10853",
  },
  {
    hotel_id: "21384",
    last_updated: "2023-11-05T18:53:16.191Z",
    name: "Kilifi Bay Beach Resort",
    country: "KE",
    region: "Kilifi County",
    city: "Kilifi",
    city_Id: "395",
  },
  {
    hotel_id: "21386",
    last_updated: "2023-11-05T18:53:16.191Z",
    name: "The Oakwood Hotel",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "21387",
    last_updated: "2023-11-05T18:53:16.191Z",
    name: "Baobab Sea Lodge",
    country: "KE",
    region: "Kilifi County",
    city: "Kilifi",
    city_Id: "395",
  },
  {
    hotel_id: "21388",
    last_updated: "2023-11-05T18:53:16.191Z",
    name: "La Mada Hotel",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "21391",
    last_updated: "2023-11-05T18:53:16.191Z",
    name: "Hunters Lodge",
    country: "KE",
    region: "Makueni",
    city: "Twaandu",
    city_Id: "7478",
  },
  {
    hotel_id: "21392",
    last_updated: "2023-11-05T18:53:16.191Z",
    name: "Kilima Safari Camp",
    country: "KE",
    region: "",
    city: "Kajiado",
    city_Id: "3563",
  },
  {
    hotel_id: "21395",
    last_updated: "2023-11-05T18:53:16.191Z",
    name: "Fig Tree Camp - Maasai Mara",
    country: "KE",
    region: "Narok",
    city: "Narok",
    city_Id: "10828",
  },
  {
    hotel_id: "21403",
    last_updated: "2023-11-05T18:53:16.194Z",
    name: "Baobab Beach Resort & Spa",
    country: "KE",
    region: "Kwale",
    city: "Kwale",
    city_Id: "10853",
  },
  {
    hotel_id: "21405",
    last_updated: "2023-11-05T18:53:16.194Z",
    name: "PrideInn Flamingo Beach Resort & Spa",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "21408",
    last_updated: "2023-11-05T18:53:16.194Z",
    name: "PrideInn Paradise Beach Resort Resort and Spa",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "21409",
    last_updated: "2023-11-05T18:53:16.194Z",
    name: "PrideInn Hotel Diani",
    country: "KE",
    region: "Kwale",
    city: "Kwale",
    city_Id: "10853",
  },
  {
    hotel_id: "21410",
    last_updated: "2023-11-05T18:53:16.194Z",
    name: "PrideInn Express Nyali",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "21414",
    last_updated: "2023-11-05T18:53:16.194Z",
    name: "Premium Inn",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "21417",
    last_updated: "2023-11-05T18:53:16.194Z",
    name: "PrideInn Azure Nairobi",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "21483",
    last_updated: "2023-11-05T18:53:16.196Z",
    name: "Lantana Galu Beach",
    country: "KE",
    region: "Kwale",
    city: "Kwale",
    city_Id: "10853",
  },
  {
    hotel_id: "21838",
    last_updated: "2023-11-05T18:53:16.198Z",
    name: "Villa Kalista",
    country: "KE",
    region: "Kwale",
    city: "Kwale",
    city_Id: "10853",
  },
  {
    hotel_id: "22290",
    last_updated: "2023-11-05T18:53:16.202Z",
    name: "Windsor Golf Hotel & Country Club",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "22394",
    last_updated: "2023-11-05T18:53:16.202Z",
    name: "Ashnil Aruba Lodge",
    country: "KE",
    region: "",
    city: "Taita-Taveta",
    city_Id: "8090",
  },
  {
    hotel_id: "22396",
    last_updated: "2023-11-05T18:53:16.202Z",
    name: "Ashnil Mara Camp",
    country: "KE",
    region: "Narok",
    city: "Narok",
    city_Id: "10828",
  },
  {
    hotel_id: "22398",
    last_updated: "2023-11-05T18:53:16.202Z",
    name: "Ashnil Samburu Camp",
    country: "KE",
    region: "Isiolo",
    city: "Isiolo",
    city_Id: "7950",
  },
  {
    hotel_id: "22524",
    last_updated: "2023-11-05T18:53:16.208Z",
    name: "Cocoa Boutique Hotel",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "22525",
    last_updated: "2023-11-05T18:53:16.208Z",
    name: "Salt Lick Safari Lodge",
    country: "KE",
    region: "",
    city: "Taita-Taveta",
    city_Id: "8090",
  },
  {
    hotel_id: "22526",
    last_updated: "2023-11-05T18:53:16.208Z",
    name: "Taita Hills Safari Resort & Spa",
    country: "KE",
    region: "",
    city: "Taita-Taveta",
    city_Id: "8090",
  },
  {
    hotel_id: "22571",
    last_updated: "2023-11-05T18:53:16.208Z",
    name: "Diani Reef Beach Resort & Spa",
    country: "KE",
    region: "Kwale",
    city: "Kwale",
    city_Id: "10853",
  },
  {
    hotel_id: "22889",
    last_updated: "2023-11-05T18:53:16.210Z",
    name: "Southern Palms Beach Resort",
    country: "KE",
    region: "Kwale",
    city: "Kwale",
    city_Id: "10853",
  },
  {
    hotel_id: "22909",
    last_updated: "2023-11-05T18:53:16.210Z",
    name: "Bamburi Beach Hotel",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "24174",
    last_updated: "2023-11-05T18:53:16.229Z",
    name: "Kilua Beach Resort",
    country: "KE",
    region: "Mombasa",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "24436",
    last_updated: "2023-11-05T18:53:16.231Z",
    name: "Airport Gate View Hotel",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "24437",
    last_updated: "2023-11-05T18:53:16.231Z",
    name: "Airport Landing Hotel",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "24442",
    last_updated: "2023-11-05T18:53:16.231Z",
    name: "Capital Heights Hotel",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "24447",
    last_updated: "2023-11-05T18:53:16.234Z",
    name: "Corat Hotel",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "24451",
    last_updated: "2023-11-05T18:53:16.234Z",
    name: "Davis Court",
    country: "KE",
    region: "Nyeri",
    city: "Nyeri",
    city_Id: "7947",
  },
  {
    hotel_id: "24712",
    last_updated: "2023-11-05T18:53:16.236Z",
    name: "Impala Hotel",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "24714",
    last_updated: "2023-11-05T18:53:16.238Z",
    name: "Imperial Hotel Express",
    country: "KE",
    region: "Kisumu",
    city: "Kisumu",
    city_Id: "7410",
  },
  {
    hotel_id: "24732",
    last_updated: "2023-11-05T18:53:16.238Z",
    name: "Marble Arch Hotel",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "24749",
    last_updated: "2023-11-05T18:53:16.238Z",
    name: "Pinecone Hotel",
    country: "KE",
    region: "Kisumu",
    city: "Kisumu",
    city_Id: "7410",
  },
  {
    hotel_id: "24752",
    last_updated: "2023-11-05T18:53:16.238Z",
    name: "Red Ruby Hotel",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "24756",
    last_updated: "2023-11-05T18:53:16.238Z",
    name: "Rosslyn Studios",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "24769",
    last_updated: "2023-11-05T18:53:16.238Z",
    name: "Stardom Hotel",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "24776",
    last_updated: "2023-11-05T18:53:16.238Z",
    name: "Victoria Comfort Inn",
    country: "KE",
    region: "Kisumu",
    city: "Kisumu",
    city_Id: "7410",
  },
  {
    hotel_id: "24782",
    last_updated: "2023-11-05T18:53:16.238Z",
    name: "Villa Ameera Malindi",
    country: "KE",
    region: "Kilifi County",
    city: "Kilifi",
    city_Id: "395",
  },
  {
    hotel_id: "24783",
    last_updated: "2023-11-05T18:53:16.238Z",
    name: "Meltonia Luxury Suites",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "24793",
    last_updated: "2023-11-05T18:53:16.238Z",
    name: "Wida Resort Kilimani",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "24795",
    last_updated: "2023-11-05T18:53:16.238Z",
    name: "Wuduria Hotel",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "24804",
    last_updated: "2023-11-05T18:53:16.238Z",
    name: "Sirikwa Hotel",
    country: "KE",
    region: "",
    city: "Uasin Gishu",
    city_Id: "8022",
  },
  {
    hotel_id: "24857",
    last_updated: "2023-11-05T18:53:16.239Z",
    name: "Fenns Cozy Apartment - Nyali",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "24872",
    last_updated: "2023-11-05T18:53:16.239Z",
    name: "Desmond Tutu Guest House",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "24950",
    last_updated: "2023-11-05T18:53:16.239Z",
    name: "Olive Gardens Hotel Nairobi",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "24972",
    last_updated: "2023-11-05T18:53:16.239Z",
    name: "The King Post",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "25011",
    last_updated: "2023-11-05T18:53:16.239Z",
    name: "Chester Hotel",
    country: "KE",
    region: "",
    city: "Nakuru",
    city_Id: "4727",
  },
  {
    hotel_id: "25021",
    last_updated: "2023-11-05T18:53:16.239Z",
    name: "Paleo Hotel & Spa",
    country: "KE",
    region: "",
    city: "Kiambu",
    city_Id: "7991",
  },
  {
    hotel_id: "25058",
    last_updated: "2023-11-05T18:53:16.243Z",
    name: "Fairfield Resort",
    country: "KE",
    region: "",
    city: "Nakuru",
    city_Id: "4727",
  },
  {
    hotel_id: "25059",
    last_updated: "2023-11-05T18:53:16.243Z",
    name: "Hotel Genevieve",
    country: "KE",
    region: "",
    city: "Nakuru",
    city_Id: "4727",
  },
  {
    hotel_id: "25073",
    last_updated: "2023-11-05T18:53:16.243Z",
    name: "Ogalis K-coast Hotel",
    country: "KE",
    region: "",
    city: "Kilifi",
    city_Id: "395",
  },
  {
    hotel_id: "25079",
    last_updated: "2023-11-05T18:53:16.243Z",
    name: "Hotel Nomad",
    country: "KE",
    region: "",
    city: "Kajiado",
    city_Id: "3563",
  },
  {
    hotel_id: "25095",
    last_updated: "2023-11-05T18:53:16.243Z",
    name: "Rabi Hotel",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "25096",
    last_updated: "2023-11-05T18:53:16.243Z",
    name: "Rozala Motel",
    country: "KE",
    region: "Siaya",
    city: "Siaya",
    city_Id: "8092",
  },
  {
    hotel_id: "25098",
    last_updated: "2023-11-05T18:53:16.243Z",
    name: "Signal Gardens Hotel",
    country: "KE",
    region: "Kitui",
    city: "Kitui",
    city_Id: "8093",
  },
  {
    hotel_id: "25099",
    last_updated: "2023-11-05T18:53:16.243Z",
    name: "Siala Apartments",
    country: "KE",
    region: "Siaya",
    city: "Siaya",
    city_Id: "8092",
  },
  {
    hotel_id: "25157",
    last_updated: "2023-11-05T18:53:16.243Z",
    name: "Mash Park Hotel",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "25404",
    last_updated: "2023-11-05T18:53:16.243Z",
    name: "Regency Park Hotel Mombasa",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "25672",
    last_updated: "2023-11-05T18:53:16.243Z",
    name: "Kate's Apartments",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "25713",
    last_updated: "2023-11-05T18:53:16.243Z",
    name: "Mtoni Resort",
    country: "KE",
    region: "Nyeri",
    city: "Nyeri",
    city_Id: "7947",
  },
  {
    hotel_id: "25761",
    last_updated: "2023-11-05T18:53:16.243Z",
    name: "Sports Road Apartments by Dunhill Serviced Apartments",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "25768",
    last_updated: "2023-11-05T18:53:16.246Z",
    name: "Sunrise Resort Apartments & Spa",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "25776",
    last_updated: "2023-11-05T18:53:16.246Z",
    name: "Taarifa Suites by Dunhill Serviced Apartments",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "25799",
    last_updated: "2023-11-05T18:53:16.246Z",
    name: "West Park Hotel",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "25938",
    last_updated: "2023-11-05T18:53:16.246Z",
    name: "The Curve by the Park Apartments",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "25994",
    last_updated: "2023-11-05T18:53:16.246Z",
    name: "Lodwar Acacia Residences",
    country: "KE",
    region: "Turkana",
    city: "Turkana",
    city_Id: "11153",
  },
  {
    hotel_id: "25995",
    last_updated: "2023-11-05T18:53:16.246Z",
    name: "Deluxe Homes",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "26002",
    last_updated: "2023-11-05T18:53:16.246Z",
    name: "Eseriani The Resort",
    country: "KE",
    region: "",
    city: "Nakuru",
    city_Id: "4727",
  },
  {
    hotel_id: "26993",
    last_updated: "2023-11-05T18:53:16.275Z",
    name: "Neptune Mara Rianta Luxury Camp",
    country: "KE",
    region: "Narok",
    city: "Narok",
    city_Id: "10828",
  },
  {
    hotel_id: "26996",
    last_updated: "2023-11-05T18:53:16.275Z",
    name: "Neptune Palm Beach Boutique Resort & Spa",
    country: "KE",
    region: "Kwale",
    city: "Kwale",
    city_Id: "10853",
  },
  {
    hotel_id: "26998",
    last_updated: "2023-11-05T18:53:16.275Z",
    name: "Neptune Paradise Beach Resort & Spa",
    country: "KE",
    region: "Kwale",
    city: "Kwale",
    city_Id: "10853",
  },
  {
    hotel_id: "27000",
    last_updated: "2023-11-05T18:53:16.275Z",
    name: "Neptune Village Beach Resort & Spa ",
    country: "KE",
    region: "Kwale",
    city: "Kwale",
    city_Id: "10853",
  },
  {
    hotel_id: "27064",
    last_updated: "2023-11-05T18:53:16.279Z",
    name: "Jacaranda Indian Ocean Beach Resort",
    country: "KE",
    region: "Kwale",
    city: "Kwale",
    city_Id: "10853",
  },
  {
    hotel_id: "27813",
    last_updated: "2023-11-05T18:53:16.308Z",
    name: "Pine Breeze Getaway Lukenya",
    country: "KE",
    region: "",
    city: "Machakos",
    city_Id: "8145",
  },
  {
    hotel_id: "27896",
    last_updated: "2023-11-05T18:53:16.308Z",
    name: "Hennessis Hotel",
    country: "KE",
    region: "",
    city: "Kiambu",
    city_Id: "7991",
  },
  {
    hotel_id: "27990",
    last_updated: "2023-11-05T18:53:16.308Z",
    name: "Eco Villas Watamu",
    country: "KE",
    region: "Kilifi County",
    city: "Kilifi",
    city_Id: "395",
  },
  {
    hotel_id: "28195",
    last_updated: "2023-11-05T18:53:16.308Z",
    name: "Raha Suites",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28242",
    last_updated: "2023-11-05T18:53:16.308Z",
    name: "Upani in Diani",
    country: "KE",
    region: "Kwale",
    city: "Kwale",
    city_Id: "10853",
  },
  {
    hotel_id: "28282",
    last_updated: "2023-11-05T18:53:16.308Z",
    name: "Kenyan House Malindi",
    country: "KE",
    region: "",
    city: "Machakos",
    city_Id: "8145",
  },
  {
    hotel_id: "28305",
    last_updated: "2023-11-05T18:53:16.308Z",
    name: "Mish Comfy House",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28307",
    last_updated: "2023-11-05T18:53:16.308Z",
    name: "Wonderland Destination Resort",
    country: "KE",
    region: "",
    city: "Nakuru",
    city_Id: "4727",
  },
  {
    hotel_id: "28913",
    last_updated: "2023-11-05T18:53:16.373Z",
    name: "Raygreen Hotel",
    country: "KE",
    region: "",
    city: "Kericho",
    city_Id: "8067",
  },
  {
    hotel_id: "28924",
    last_updated: "2023-11-05T18:53:16.373Z",
    name: "Fahari Triple Hotel",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28967",
    last_updated: "2023-11-05T18:53:16.373Z",
    name: "Comfort Zone Homes Thika Road",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "30647",
    last_updated: "2023-11-05T18:53:16.443Z",
    name: "Neptune Beach Resort ",
    country: "KE",
    region: "Mombasa",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "33623",
    last_updated: "2023-11-05T18:53:16.568Z",
    name: "Acacia Premier Hotel",
    country: "KE",
    region: "Kisumu",
    city: "Kisumu",
    city_Id: "7410",
  },
  {
    hotel_id: "34620",
    last_updated: "2023-11-05T18:53:16.633Z",
    name: "Mombasa Beach Hotel",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "36060",
    last_updated: "2023-11-05T18:53:16.766Z",
    name: "Enashipai Resort & Spa",
    country: "KE",
    region: "Nakuru",
    city: "Nakuru",
    city_Id: "4727",
  },
  {
    hotel_id: "37044",
    last_updated: "2023-11-05T18:53:16.789Z",
    name: "PrideInn Plaza Hotel",
    country: "KE",
    region: "Machakos",
    city: "Machakos",
    city_Id: "8145",
  },
  {
    hotel_id: "37057",
    last_updated: "2023-11-05T18:53:16.789Z",
    name: "PrideInn Mara Camp",
    country: "KE",
    region: "Narok",
    city: "Narok",
    city_Id: "10828",
  },
  {
    hotel_id: "37606",
    last_updated: "2023-11-05T18:53:16.825Z",
    name: "Muthu Lake Naivasha Country Club, Naivasha",
    country: "KE",
    region: "",
    city: "Nakuru",
    city_Id: "4727",
  },
  {
    hotel_id: "37915",
    last_updated: "2023-11-05T18:53:16.842Z",
    name: "Muthu Keekorok Lodge, Maasai Mara, Narok",
    country: "KE",
    region: "Narok",
    city: "Narok",
    city_Id: "10828",
  },
  {
    hotel_id: "37916",
    last_updated: "2023-11-05T18:53:16.842Z",
    name: "Muthu Nyali Beach Hotel & Spa, Nyali, Mombasa",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "37981",
    last_updated: "2023-11-05T18:53:16.844Z",
    name: "Muthu Sovereign Suites & Spa, Limuru Rd, Near Nairobi",
    country: "KE",
    region: "",
    city: "Kiambu",
    city_Id: "7991",
  },
  {
    hotel_id: "38287",
    last_updated: "2023-11-05T18:53:16.862Z",
    name: "Regency Mount Kenya Hotel",
    country: "KE",
    region: "",
    city: "Meru",
    city_Id: "8003",
  },
  {
    hotel_id: "38407",
    last_updated: "2023-11-05T18:53:16.862Z",
    name: "Comfort Zone Homes Athi River",
    country: "KE",
    region: "",
    city: "Machakos",
    city_Id: "8145",
  },
  {
    hotel_id: "38409",
    last_updated: "2023-11-05T18:53:16.862Z",
    name: "Darajani Hotel Ltd",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "38415",
    last_updated: "2023-11-05T18:53:16.862Z",
    name: "Kimya House",
    country: "KE",
    region: "",
    city: "Kilifi",
    city_Id: "395",
  },
  {
    hotel_id: "38422",
    last_updated: "2023-11-05T18:53:16.862Z",
    name: "African Dada Resort",
    country: "KE",
    region: "Kilifi County",
    city: "Kilifi",
    city_Id: "395",
  },
  {
    hotel_id: "38423",
    last_updated: "2023-11-05T18:53:16.862Z",
    name: "Koru Country Club",
    country: "KE",
    region: "Kisumu",
    city: "Kisumu",
    city_Id: "7410",
  },
  {
    hotel_id: "38424",
    last_updated: "2023-11-05T18:53:16.862Z",
    name: "Hotel Tobriana",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "38425",
    last_updated: "2023-11-05T18:53:16.862Z",
    name: "Glory Ocean Villas - Diani ",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "38444",
    last_updated: "2023-11-05T18:53:16.865Z",
    name: "Fahari House Watamu",
    country: "KE",
    region: "",
    city: "Kilifi",
    city_Id: "395",
  },
  {
    hotel_id: "38447",
    last_updated: "2023-11-05T18:53:16.865Z",
    name: "Bridge Hotel Mombasa",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "38463",
    last_updated: "2023-11-05T18:53:16.865Z",
    name: "Merry Villa Hotel & Apartments",
    country: "KE",
    region: "",
    city: "Kilifi",
    city_Id: "395",
  },
  {
    hotel_id: "38467",
    last_updated: "2023-11-05T18:53:16.867Z",
    name: "Mvuvi Lodge Watamu",
    country: "KE",
    region: "",
    city: "Meru",
    city_Id: "8003",
  },
  {
    hotel_id: "38478",
    last_updated: "2023-11-05T18:53:16.867Z",
    name: "Hotel Hippo Buck",
    country: "KE",
    region: "Homa Bay",
    city: "Homa Bay",
    city_Id: "8021",
  },
  {
    hotel_id: "38479",
    last_updated: "2023-11-05T18:53:16.867Z",
    name: "Place Caravan Hotel - Kakamega",
    country: "KE",
    region: "",
    city: "Kakamega",
    city_Id: "7995",
  },
  {
    hotel_id: "47481",
    last_updated: "2023-11-05T18:53:17.455Z",
    name: "Mzima Beach Resort - Diani Beach",
    country: "KE",
    region: "Kwale",
    city: "Galu",
    city_Id: "12288",
  },
  {
    hotel_id: "47935",
    last_updated: "2023-11-05T18:53:17.474Z",
    name: "Golf Hotel Kakamega",
    country: "KE",
    region: "Kakamega",
    city: "Kakamega",
    city_Id: "7995",
  },
  {
    hotel_id: "52582",
    last_updated: "2023-11-05T18:53:17.804Z",
    name: "Olengoti Eco Safari Camp",
    country: "KE",
    region: "Narok",
    city: "Talek",
    city_Id: "12783",
  },
  {
    hotel_id: "53246",
    last_updated: "2023-11-05T18:53:17.854Z",
    name: "Sincerely Yours Suites & Serviced Apartments",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "53265",
    last_updated: "2023-11-05T18:53:17.856Z",
    name: "Ole Sereni",
    country: "KE",
    region: "Nairobi",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "53273",
    last_updated: "2023-11-05T18:53:17.856Z",
    name: "Emara Ole-Sereni",
    country: "KE",
    region: "Nairobi",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "53580",
    last_updated: "2023-11-05T18:53:17.874Z",
    name: "Royal Tulip Canaan Nairobi",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "55220",
    last_updated: "2023-11-05T18:53:17.945Z",
    name: "PrideInn Azure Nairobi",
    country: "KE",
    region: "Nairobi",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "56645",
    last_updated: "2023-11-05T18:53:18.014Z",
    name: "Baobab Beach Resort & Spa",
    country: "KE",
    region: "",
    city: "Diani Beach",
    city_Id: "4400",
  },
  {
    hotel_id: "57369",
    last_updated: "2023-11-05T18:53:18.055Z",
    name: "Holiday Inn Nairobi Two Rivers Mall",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "57614",
    last_updated: "2023-11-05T18:53:18.066Z",
    name: "The Panari Hotel",
    country: "KE",
    region: "nairobi",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "59836",
    last_updated: "2023-11-05T18:53:18.263Z",
    name: "Karen Blixen Camp Masai Mara",
    country: "KE",
    region: "Narok",
    city: "Narok",
    city_Id: "10828",
  },
  {
    hotel_id: "60703",
    last_updated: "2023-11-05T18:53:18.308Z",
    name: "Hotel Mokka City",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "61821",
    last_updated: "2023-11-05T18:53:18.388Z",
    name: "Maiyan Luxury Resort",
    country: "KE",
    region: "Nanyuki",
    city: "Laikipia",
    city_Id: "8071",
  },
  {
    hotel_id: "61823",
    last_updated: "2023-11-05T18:53:18.388Z",
    name: "Falcon Heights Hotel",
    country: "KE",
    region: "Laikipia",
    city: "Nanyuki, Laikipia",
    city_Id: "13933",
  },
  {
    hotel_id: "61830",
    last_updated: "2023-11-05T18:53:18.388Z",
    name: "Hotel English Point",
    country: "KE",
    region: "Nyali",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "62165",
    last_updated: "2023-11-05T18:53:18.415Z",
    name: "Surana Buffalo Springs",
    country: "KE",
    region: "Ngare Mara Gate",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "68407",
    last_updated: "2023-11-05T18:53:18.864Z",
    name: "Melvic Inn Kisumu",
    country: "KE",
    region: "",
    city: "Kisumu",
    city_Id: "7410",
  },
  {
    hotel_id: "68411",
    last_updated: "2023-11-05T18:53:18.864Z",
    name: "Jumbo Club Watamu",
    country: "KE",
    region: "",
    city: "Kilifi",
    city_Id: "395",
  },
  {
    hotel_id: "68695",
    last_updated: "2023-11-05T18:53:18.916Z",
    name: "Zanale Stays Furnished Apartment",
    country: "KE",
    region: "Nairobi",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "68696",
    last_updated: "2023-11-05T18:53:18.916Z",
    name: "Zendo Suite",
    country: "KE",
    region: "Nairobi",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "69143",
    last_updated: "2023-11-05T18:53:18.942Z",
    name: "Msambweni Beach House",
    country: "KE",
    region: "Kwale County",
    city: "Kwale",
    city_Id: "10853",
  },
  {
    hotel_id: "70072",
    last_updated: "2023-11-05T18:53:18.975Z",
    name: "Comfy Dhows Hotel, Kilifi",
    country: "KE",
    region: "",
    city: "Kilifi",
    city_Id: "395",
  },
  {
    hotel_id: "70076",
    last_updated: "2023-11-05T18:53:18.975Z",
    name: "Le Grand Victoria Hotel",
    country: "KE",
    region: "Coastal Kenya",
    city: "Bamburi",
    city_Id: "7652",
  },
  {
    hotel_id: "71134",
    last_updated: "2023-11-05T18:53:19.065Z",
    name: "Broadwalk Residency",
    country: "KE",
    region: "Nairobi",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "71150",
    last_updated: "2023-11-05T18:53:19.067Z",
    name: "Convent International Hotel- Nairobi",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "71153",
    last_updated: "2023-11-05T18:53:19.067Z",
    name: "Melvic Hotel Kisumu",
    country: "KE",
    region: "",
    city: "Kisumu",
    city_Id: "7410",
  },
  {
    hotel_id: "71154",
    last_updated: "2023-11-05T18:53:19.067Z",
    name: "Karimba Lodge - Meru",
    country: "KE",
    region: "",
    city: "Meru",
    city_Id: "8003",
  },
  {
    hotel_id: "71157",
    last_updated: "2023-11-05T18:53:19.067Z",
    name: "Kisimani Eco Resort & Spa",
    country: "KE",
    region: "",
    city: "Nakuru",
    city_Id: "4727",
  },
  {
    hotel_id: "71194",
    last_updated: "2023-11-05T18:53:19.071Z",
    name: "Spice Afrique",
    country: "KE",
    region: "Nyanza",
    city: "Kisumu",
    city_Id: "7410",
  },
  {
    hotel_id: "71196",
    last_updated: "2023-11-05T18:53:19.071Z",
    name: "Hotel Vesloni",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "71200",
    last_updated: "2023-11-05T18:53:19.071Z",
    name: "GS Grand Hotel",
    country: "KE",
    region: "",
    city: "Kiambu",
    city_Id: "7991",
  },
  {
    hotel_id: "71206",
    last_updated: "2023-11-05T18:53:19.071Z",
    name: "Karibuni Palace Mtwapa",
    country: "KE",
    region: "",
    city: "Kilifi",
    city_Id: "395",
  },
  {
    hotel_id: "71326",
    last_updated: "2023-11-05T18:53:19.076Z",
    name: "Avenue Apartments Nyali by CHH",
    country: "KE",
    region: "Coastal Kenya",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "71841",
    last_updated: "2023-11-05T18:53:19.112Z",
    name: "The Lukenya Resort",
    country: "KE",
    region: "Kenya",
    city: "Athi River",
    city_Id: "14812",
  },
  {
    hotel_id: "71843",
    last_updated: "2023-11-05T18:53:19.112Z",
    name: "Phoenicia Hotel Kiambu",
    country: "KE",
    region: "Kenya",
    city: "Kiambu",
    city_Id: "7991",
  },
  {
    hotel_id: "71844",
    last_updated: "2023-11-05T18:53:19.112Z",
    name: "Kilifi Blue House Resort",
    country: "KE",
    region: "Kenya",
    city: "Kilifi",
    city_Id: "395",
  },
  {
    hotel_id: "72167",
    last_updated: "2023-11-05T18:53:19.141Z",
    name: "Hermosa Garden Hotel & SPA",
    country: "KE",
    region: "Kenya",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "74174",
    last_updated: "2023-11-05T18:53:19.271Z",
    name: "Pinewood Beach Resort and Spa",
    country: "KE",
    region: "",
    city: "Diani Beach",
    city_Id: "4400",
  },
  {
    hotel_id: "21411",
    last_updated: "2023-11-05T18:53:19.406Z",
    name: "Prideinn Hotel Lantana Suites",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "22576",
    last_updated: "2023-11-05T18:53:19.424Z",
    name: "Ledger Plaza Hotel Nairobi",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "22699",
    last_updated: "2023-11-05T18:53:19.434Z",
    name: "Ziwa Beach Resort",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "24998",
    last_updated: "2023-11-05T18:53:19.463Z",
    name: "Hillpark Hotel",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "25409",
    last_updated: "2023-11-05T18:53:19.465Z",
    name: "Kentania Hotel",
    country: "KE",
    region: "",
    city: "Nakuru",
    city_Id: "4727",
  },
  {
    hotel_id: "25416",
    last_updated: "2023-11-05T18:53:19.465Z",
    name: "West Wood Hotel Nairobi",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "25625",
    last_updated: "2023-11-05T18:53:19.465Z",
    name: "Fina Gardens Resort",
    country: "KE",
    region: "",
    city: "Nakuru",
    city_Id: "4727",
  },
  {
    hotel_id: "25638",
    last_updated: "2023-11-05T18:53:19.465Z",
    name: "Hemak Suites",
    country: "KE",
    region: "Samburu",
    city: "Samburu",
    city_Id: "11147",
  },
  {
    hotel_id: "25659",
    last_updated: "2023-11-05T18:53:19.465Z",
    name: "Inter Leisure Hotel",
    country: "KE",
    region: "",
    city: "Machakos",
    city_Id: "8145",
  },
  {
    hotel_id: "25706",
    last_updated: "2023-11-05T18:53:19.465Z",
    name: "Meru Paramount",
    country: "KE",
    region: "",
    city: "Meru",
    city_Id: "8003",
  },
  {
    hotel_id: "25784",
    last_updated: "2023-11-05T18:53:19.467Z",
    name: "The Stand Leisure Hotel",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "25798",
    last_updated: "2023-11-05T18:53:19.467Z",
    name: "Waridi Paradise Hotel & Suites",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "26001",
    last_updated: "2023-11-05T18:53:19.467Z",
    name: "Victoria Sands Lodge",
    country: "KE",
    region: "Homa Bay",
    city: "Homa Bay",
    city_Id: "8021",
  },
  {
    hotel_id: "26991",
    last_updated: "2023-11-05T18:53:19.482Z",
    name: "Jacaranda Hotel Nairobi",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "27754",
    last_updated: "2023-11-05T18:53:19.528Z",
    name: "Emara Ole-Sereni",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "27804",
    last_updated: "2023-11-05T18:53:19.530Z",
    name: "APA Apartment (Utopia Holiday Homes)",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "27812",
    last_updated: "2023-11-05T18:53:19.531Z",
    name: "Winstonia Hotel",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "27816",
    last_updated: "2023-11-05T18:53:19.531Z",
    name: "Milele Resort Nakuru",
    country: "KE",
    region: "",
    city: "Nakuru",
    city_Id: "4727",
  },
  {
    hotel_id: "27817",
    last_updated: "2023-11-05T18:53:19.531Z",
    name: "Masada Hotel",
    country: "KE",
    region: "",
    city: "Nakuru",
    city_Id: "4727",
  },
  {
    hotel_id: "27883",
    last_updated: "2023-11-05T18:53:19.538Z",
    name: "Kilima Camp",
    country: "KE",
    region: "Narok",
    city: "Narok",
    city_Id: "10828",
  },
  {
    hotel_id: "27884",
    last_updated: "2023-11-05T18:53:19.538Z",
    name: "Milimani Beach Resort",
    country: "KE",
    region: "Kisumu",
    city: "Kisumu",
    city_Id: "7410",
  },
  {
    hotel_id: "27885",
    last_updated: "2023-11-05T18:53:19.538Z",
    name: "Western Star Hotel",
    country: "KE",
    region: "",
    city: "Kakamega",
    city_Id: "7995",
  },
  {
    hotel_id: "27886",
    last_updated: "2023-11-05T18:53:19.538Z",
    name: "African Dream Cottages",
    country: "KE",
    region: "Kwale",
    city: "Kwale",
    city_Id: "10853",
  },
  {
    hotel_id: "27887",
    last_updated: "2023-11-05T18:53:19.538Z",
    name: "Morning Star Diani Beach Resort",
    country: "KE",
    region: "Kwale",
    city: "Kwale",
    city_Id: "10853",
  },
  {
    hotel_id: "27888",
    last_updated: "2023-11-05T18:53:19.538Z",
    name: "Sai Rock Hotel and Beach Resorts",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "27889",
    last_updated: "2023-11-05T18:53:19.538Z",
    name: "Bonitas Guest House",
    country: "KE",
    region: "",
    city: "Kilifi",
    city_Id: "395",
  },
  {
    hotel_id: "27890",
    last_updated: "2023-11-05T18:53:19.538Z",
    name: "HomeBase Gardens Guesthouse",
    country: "KE",
    region: "",
    city: "Nakuru",
    city_Id: "4727",
  },
  {
    hotel_id: "27891",
    last_updated: "2023-11-05T18:53:19.538Z",
    name: "Hotel Wagon Wheel",
    country: "KE",
    region: "",
    city: "Nakuru",
    city_Id: "4727",
  },
  {
    hotel_id: "27892",
    last_updated: "2023-11-05T18:53:19.538Z",
    name: "CHAK Guest House and Conference Centre",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "27893",
    last_updated: "2023-11-05T18:53:19.540Z",
    name: "Eron Hotel",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "27894",
    last_updated: "2023-11-05T18:53:19.540Z",
    name: "Freshwind Villas",
    country: "KE",
    region: "",
    city: "Machakos",
    city_Id: "8145",
  },
  {
    hotel_id: "27895",
    last_updated: "2023-11-05T18:53:19.540Z",
    name: "Greenvale Hotel",
    country: "KE",
    region: "",
    city: "Kiambu",
    city_Id: "7991",
  },
  {
    hotel_id: "27897",
    last_updated: "2023-11-05T18:53:19.540Z",
    name: "Hotel Barkley",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "27898",
    last_updated: "2023-11-05T18:53:19.540Z",
    name: "Hotel Troy",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "27899",
    last_updated: "2023-11-05T18:53:19.540Z",
    name: "Kahama Hotel",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "27900",
    last_updated: "2023-11-05T18:53:19.540Z",
    name: "Khweza Bed and Breakfast",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "27901",
    last_updated: "2023-11-05T18:53:19.540Z",
    name: "Margarita House",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "27908",
    last_updated: "2023-11-05T18:53:19.540Z",
    name: "Pori City Hotel",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "27909",
    last_updated: "2023-11-05T18:53:19.540Z",
    name: "Lily Palm Resort",
    country: "KE",
    region: "",
    city: "Kilifi",
    city_Id: "395",
  },
  {
    hotel_id: "27913",
    last_updated: "2023-11-05T18:53:19.540Z",
    name: "7Island Resort",
    country: "KE",
    region: "",
    city: "Kilifi",
    city_Id: "395",
  },
  {
    hotel_id: "27925",
    last_updated: "2023-11-05T18:53:19.542Z",
    name: "Abbas House Game Lodge",
    country: "KE",
    region: "Laikipia",
    city: "Laikipia",
    city_Id: "8071",
  },
  {
    hotel_id: "27926",
    last_updated: "2023-11-05T18:53:19.542Z",
    name: "Acacia Tree Lodge",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "27927",
    last_updated: "2023-11-05T18:53:19.542Z",
    name: "Alba Hotel Meru",
    country: "KE",
    region: "",
    city: "Meru",
    city_Id: "8003",
  },
  {
    hotel_id: "27928",
    last_updated: "2023-11-05T18:53:19.542Z",
    name: "Amelia Hotel Karen",
    country: "KE",
    region: "Samburu",
    city: "Samburu",
    city_Id: "11147",
  },
  {
    hotel_id: "27929",
    last_updated: "2023-11-05T18:53:19.542Z",
    name: "At Home With Karuris",
    country: "KE",
    region: "",
    city: "Uasin Gishu",
    city_Id: "8022",
  },
  {
    hotel_id: "27930",
    last_updated: "2023-11-05T18:53:19.542Z",
    name: "Avalor Suites Milimani",
    country: "KE",
    region: "Samburu",
    city: "Samburu",
    city_Id: "11147",
  },
  {
    hotel_id: "27931",
    last_updated: "2023-11-05T18:53:19.542Z",
    name: "Bamburi Beach Hotel",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "27932",
    last_updated: "2023-11-05T18:53:19.542Z",
    name: "Batians Apartment Hotel",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "27933",
    last_updated: "2023-11-05T18:53:19.542Z",
    name: "Blue Marlin Beach Hotel",
    country: "KE",
    region: "Kwale",
    city: "Kwale",
    city_Id: "10853",
  },
  {
    hotel_id: "27934",
    last_updated: "2023-11-05T18:53:19.542Z",
    name: "Bykay Hotel",
    country: "KE",
    region: "Siaya",
    city: "Siaya",
    city_Id: "8092",
  },
  {
    hotel_id: "27935",
    last_updated: "2023-11-05T18:53:19.542Z",
    name: "Chrystal Apartments Baharini",
    country: "KE",
    region: "Kwale",
    city: "Kwale",
    city_Id: "10853",
  },
  {
    hotel_id: "27936",
    last_updated: "2023-11-05T18:53:19.544Z",
    name: "Comfort Gardens Guesthouse",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "27937",
    last_updated: "2023-11-05T18:53:19.544Z",
    name: "Cowrie Shell Beach Apartments",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "27938",
    last_updated: "2023-11-05T18:53:19.544Z",
    name: "Diamond Hotel",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "27939",
    last_updated: "2023-11-05T18:53:19.544Z",
    name: "Diamond Plaza Apartments",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "27941",
    last_updated: "2023-11-05T18:53:19.544Z",
    name: "Doric Cottages Diani",
    country: "KE",
    region: "Kwale",
    city: "Kwale",
    city_Id: "10853",
  },
  {
    hotel_id: "27942",
    last_updated: "2023-11-05T18:53:19.544Z",
    name: "Enashipai Resort and Spa",
    country: "KE",
    region: "",
    city: "Nakuru",
    city_Id: "4727",
  },
  {
    hotel_id: "27943",
    last_updated: "2023-11-05T18:53:19.544Z",
    name: "Fair Acres Boutique Hotel",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "27944",
    last_updated: "2023-11-05T18:53:19.544Z",
    name: "Highlands Suites Apartment Hotel",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "27945",
    last_updated: "2023-11-05T18:53:19.544Z",
    name: "Hotel Emerald",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "27946",
    last_updated: "2023-11-05T18:53:19.544Z",
    name: "Hotel EnglishPoint and Spa",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "27947",
    last_updated: "2023-11-05T18:53:19.544Z",
    name: "Ivory Resort Hotel",
    country: "KE",
    region: "Nyeri",
    city: "Nyeri",
    city_Id: "7947",
  },
  {
    hotel_id: "27948",
    last_updated: "2023-11-05T18:53:19.544Z",
    name: "Jaqanaz Resort",
    country: "KE",
    region: "Laikipia",
    city: "Laikipia",
    city_Id: "8071",
  },
  {
    hotel_id: "27949",
    last_updated: "2023-11-05T18:53:19.544Z",
    name: "Leinmach House Hotel",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "27950",
    last_updated: "2023-11-05T18:53:19.544Z",
    name: "Lido Beach Resort",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "27951",
    last_updated: "2023-11-05T18:53:19.544Z",
    name: "Mara Eden Safari Camp",
    country: "KE",
    region: "Narok",
    city: "Narok",
    city_Id: "10828",
  },
  {
    hotel_id: "27952",
    last_updated: "2023-11-05T18:53:19.544Z",
    name: "Marlin Guest Resort",
    country: "KE",
    region: "",
    city: "Nakuru",
    city_Id: "4727",
  },
  {
    hotel_id: "27953",
    last_updated: "2023-11-05T18:53:19.544Z",
    name: "Mbweha Camp",
    country: "KE",
    region: "",
    city: "Nakuru",
    city_Id: "4727",
  },
  {
    hotel_id: "27954",
    last_updated: "2023-11-05T18:53:19.544Z",
    name: "Milimani Holiday Resort",
    country: "KE",
    region: "Kisumu",
    city: "Kisumu",
    city_Id: "7410",
  },
  {
    hotel_id: "27955",
    last_updated: "2023-11-05T18:53:19.544Z",
    name: "Mtwapa Coast Breeze",
    country: "KE",
    region: "",
    city: "Kilifi",
    city_Id: "395",
  },
  {
    hotel_id: "27956",
    last_updated: "2023-11-05T18:53:19.544Z",
    name: "Nairobi Executive Suites",
    country: "KE",
    region: "",
    city: "Machakos",
    city_Id: "8145",
  },
  {
    hotel_id: "27957",
    last_updated: "2023-11-05T18:53:19.546Z",
    name: "Ngong Hills Hotel",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "27958",
    last_updated: "2023-11-05T18:53:19.546Z",
    name: "Nyayo Homestay Nairobi",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "27959",
    last_updated: "2023-11-05T18:53:19.546Z",
    name: "Ocean View Nyali Boutique Hotel",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "27960",
    last_updated: "2023-11-05T18:53:19.546Z",
    name: "Ole Itiko Cottages",
    country: "KE",
    region: "",
    city: "Nakuru",
    city_Id: "4727",
  },
  {
    hotel_id: "27961",
    last_updated: "2023-11-05T18:53:19.546Z",
    name: "Oltome Mara Magic Resort",
    country: "KE",
    region: "Narok",
    city: "Narok",
    city_Id: "10828",
  },
  {
    hotel_id: "27962",
    last_updated: "2023-11-05T18:53:19.546Z",
    name: "Oluwa Seun Beach Cottages",
    country: "KE",
    region: "",
    city: "Kilifi",
    city_Id: "395",
  },
  {
    hotel_id: "27963",
    last_updated: "2023-11-05T18:53:19.546Z",
    name: "Pendo Villas Diani Beach",
    country: "KE",
    region: "Kwale",
    city: "Kwale",
    city_Id: "10853",
  },
  {
    hotel_id: "27965",
    last_updated: "2023-11-05T18:53:19.546Z",
    name: "Plaza Beach Hotel",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "27967",
    last_updated: "2023-11-05T18:53:19.546Z",
    name: "Royal Mara Safari Lodge",
    country: "KE",
    region: "Narok",
    city: "Narok",
    city_Id: "10828",
  },
  {
    hotel_id: "27968",
    last_updated: "2023-11-05T18:53:19.546Z",
    name: "Saab Royale Resort",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "27969",
    last_updated: "2023-11-05T18:53:19.546Z",
    name: "Samatian Island Lodge",
    country: "KE",
    region: "",
    city: "Baringo",
    city_Id: "8758",
  },
  {
    hotel_id: "27970",
    last_updated: "2023-11-05T18:53:19.546Z",
    name: "Sandies Malindi Dream Garden Hotel",
    country: "KE",
    region: "",
    city: "Kilifi",
    city_Id: "395",
  },
  {
    hotel_id: "27971",
    last_updated: "2023-11-05T18:53:19.546Z",
    name: "Shamba La Salama",
    country: "KE",
    region: "Kwale",
    city: "Kwale",
    city_Id: "10853",
  },
  {
    hotel_id: "27972",
    last_updated: "2023-11-05T18:53:19.546Z",
    name: "Southern Palms Beach Resort",
    country: "KE",
    region: "Kwale",
    city: "Kwale",
    city_Id: "10853",
  },
  {
    hotel_id: "27973",
    last_updated: "2023-11-05T18:53:19.546Z",
    name: "Sweet Lake Resort",
    country: "KE",
    region: "",
    city: "Nakuru",
    city_Id: "4727",
  },
  {
    hotel_id: "27974",
    last_updated: "2023-11-05T18:53:19.546Z",
    name: "Tamarind Village Apartments",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "27975",
    last_updated: "2023-11-05T18:53:19.546Z",
    name: "Ziwa Beach Resort",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "27976",
    last_updated: "2023-11-05T18:53:19.546Z",
    name: "Falcon Heights Hotel",
    country: "KE",
    region: "Laikipia",
    city: "Laikipia",
    city_Id: "8071",
  },
  {
    hotel_id: "27978",
    last_updated: "2023-11-05T18:53:19.549Z",
    name: "Family Nest Guest House",
    country: "KE",
    region: "Kisumu",
    city: "Kisumu",
    city_Id: "7410",
  },
  {
    hotel_id: "27979",
    last_updated: "2023-11-05T18:53:19.549Z",
    name: "Lake Naivasha Resort",
    country: "KE",
    region: "",
    city: "Nakuru",
    city_Id: "4727",
  },
  {
    hotel_id: "27985",
    last_updated: "2023-11-05T18:53:19.549Z",
    name: "The Great Lakes Hotel Ltd",
    country: "KE",
    region: "Kisumu",
    city: "Kisumu",
    city_Id: "7410",
  },
  {
    hotel_id: "27986",
    last_updated: "2023-11-05T18:53:19.549Z",
    name: "Twiga Sanctuary Resort",
    country: "KE",
    region: "Kisumu",
    city: "Kisumu",
    city_Id: "7410",
  },
  {
    hotel_id: "27987",
    last_updated: "2023-11-05T18:53:19.549Z",
    name: "Sigana Guest House",
    country: "KE",
    region: "Kisumu",
    city: "Kisumu",
    city_Id: "7410",
  },
  {
    hotel_id: "27988",
    last_updated: "2023-11-05T18:53:19.549Z",
    name: "Orchid Homes",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "27989",
    last_updated: "2023-11-05T18:53:19.549Z",
    name: "Villa Leone Guest House",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "27991",
    last_updated: "2023-11-05T18:53:19.549Z",
    name: "Tisa Suites and Lounge",
    country: "KE",
    region: "Kisumu",
    city: "Kisumu",
    city_Id: "7410",
  },
  {
    hotel_id: "27992",
    last_updated: "2023-11-05T18:53:19.549Z",
    name: "Nyam Hotel",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "27993",
    last_updated: "2023-11-05T18:53:19.549Z",
    name: "Regency Inn Tudor",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "27994",
    last_updated: "2023-11-05T18:53:19.549Z",
    name: "Reata Serviced Apartments",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "27995",
    last_updated: "2023-11-05T18:53:19.549Z",
    name: "Hotel Riversand Kisumu",
    country: "KE",
    region: "Kisumu",
    city: "Kisumu",
    city_Id: "7410",
  },
  {
    hotel_id: "27996",
    last_updated: "2023-11-05T18:53:19.549Z",
    name: "Sportsview Hotel Kasarani",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "27998",
    last_updated: "2023-11-05T18:53:19.549Z",
    name: "Ranges View Lodge",
    country: "KE",
    region: "Muranga",
    city: "Muranga",
    city_Id: "8139",
  },
  {
    hotel_id: "27999",
    last_updated: "2023-11-05T18:53:19.549Z",
    name: "Acacia Furnished Apartments",
    country: "KE",
    region: "Laikipia",
    city: "Laikipia",
    city_Id: "8071",
  },
  {
    hotel_id: "28000",
    last_updated: "2023-11-05T18:53:19.549Z",
    name: "Sovereign Hotel",
    country: "KE",
    region: "Kisumu",
    city: "Kisumu",
    city_Id: "7410",
  },
  {
    hotel_id: "28002",
    last_updated: "2023-11-05T18:53:19.551Z",
    name: "The Ark Hotel",
    country: "KE",
    region: "Nyeri",
    city: "Nyeri",
    city_Id: "7947",
  },
  {
    hotel_id: "28003",
    last_updated: "2023-11-05T18:53:19.551Z",
    name: "Tumaini Cottages and Conference Centre",
    country: "KE",
    region: "",
    city: "Nakuru",
    city_Id: "4727",
  },
  {
    hotel_id: "28004",
    last_updated: "2023-11-05T18:53:19.551Z",
    name: "Poa Place Resort",
    country: "KE",
    region: "",
    city: "Uasin Gishu",
    city_Id: "8022",
  },
  {
    hotel_id: "28005",
    last_updated: "2023-11-05T18:53:19.551Z",
    name: "Ruma Tourist Lodge",
    country: "KE",
    region: "Kisumu",
    city: "Kisumu",
    city_Id: "7410",
  },
  {
    hotel_id: "28006",
    last_updated: "2023-11-05T18:53:19.551Z",
    name: "Bankhouse Karen",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28007",
    last_updated: "2023-11-05T18:53:19.551Z",
    name: "Hadassah Hotel",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28008",
    last_updated: "2023-11-05T18:53:19.551Z",
    name: "Stem Hotel",
    country: "KE",
    region: "",
    city: "Nakuru",
    city_Id: "4727",
  },
  {
    hotel_id: "28009",
    last_updated: "2023-11-05T18:53:19.551Z",
    name: "Ashari Hotel",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "28012",
    last_updated: "2023-11-05T18:53:19.551Z",
    name: "Ose Cottages",
    country: "KE",
    region: "Vihiga",
    city: "Vihiga",
    city_Id: "11356",
  },
  {
    hotel_id: "28014",
    last_updated: "2023-11-05T18:53:19.551Z",
    name: "Pearl Palace Hotel",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28015",
    last_updated: "2023-11-05T18:53:19.551Z",
    name: "Cosy Garden Guest House",
    country: "KE",
    region: "Kisumu",
    city: "Kisumu",
    city_Id: "7410",
  },
  {
    hotel_id: "28016",
    last_updated: "2023-11-05T18:53:19.551Z",
    name: "Sirville Lake Elementaita Lodge",
    country: "KE",
    region: "",
    city: "Nakuru",
    city_Id: "4727",
  },
  {
    hotel_id: "28017",
    last_updated: "2023-11-05T18:53:19.551Z",
    name: "Oakwood Hotel",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28019",
    last_updated: "2023-11-05T18:53:19.551Z",
    name: "Sun Palm Beach Hotel & Resort",
    country: "KE",
    region: "",
    city: "Kilifi",
    city_Id: "395",
  },
  {
    hotel_id: "28020",
    last_updated: "2023-11-05T18:53:19.551Z",
    name: "The Legacy Hotel and Suites",
    country: "KE",
    region: "Samburu",
    city: "Samburu",
    city_Id: "11147",
  },
  {
    hotel_id: "28021",
    last_updated: "2023-11-05T18:53:19.551Z",
    name: "Casa Tulia Hotel",
    country: "KE",
    region: "",
    city: "Nakuru",
    city_Id: "4727",
  },
  {
    hotel_id: "28022",
    last_updated: "2023-11-05T18:53:19.551Z",
    name: "Classic Guest Home",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28023",
    last_updated: "2023-11-05T18:53:19.551Z",
    name: "WE Hotel and Suites",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28024",
    last_updated: "2023-11-05T18:53:19.551Z",
    name: "The Smith Hotel",
    country: "KE",
    region: "",
    city: "Kajiado",
    city_Id: "3563",
  },
  {
    hotel_id: "28025",
    last_updated: "2023-11-05T18:53:19.551Z",
    name: "Bienvenue Delta Hotel",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28026",
    last_updated: "2023-11-05T18:53:19.553Z",
    name: "The Vic Hotel",
    country: "KE",
    region: "Kisumu",
    city: "Kisumu",
    city_Id: "7410",
  },
  {
    hotel_id: "28099",
    last_updated: "2023-11-05T18:53:19.558Z",
    name: "Nightingale Apartments",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "28113",
    last_updated: "2023-11-05T18:53:19.560Z",
    name: "Voyager 3-Bedroom Apartment - Utopia Holiday Homes",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "28126",
    last_updated: "2023-11-05T18:53:19.560Z",
    name: "Hotel Rudi",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28169",
    last_updated: "2023-11-05T18:53:19.564Z",
    name: "Castle Forest Lodge",
    country: "KE",
    region: "Kirinyaga",
    city: "Kirinyaga",
    city_Id: "8595",
  },
  {
    hotel_id: "28173",
    last_updated: "2023-11-05T18:53:19.564Z",
    name: "L'Aziz Suites",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28174",
    last_updated: "2023-11-05T18:53:19.564Z",
    name: "ACK Guesthouse Nairobi",
    country: "KE",
    region: "Samburu",
    city: "Samburu",
    city_Id: "11147",
  },
  {
    hotel_id: "28178",
    last_updated: "2023-11-05T18:53:19.564Z",
    name: "Leopard House",
    country: "KE",
    region: "",
    city: "Kilifi",
    city_Id: "395",
  },
  {
    hotel_id: "28179",
    last_updated: "2023-11-05T18:53:19.566Z",
    name: "Restead Stay Apartments",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28180",
    last_updated: "2023-11-05T18:53:19.566Z",
    name: "Stay.Plus APA Apartments Nyali",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "28182",
    last_updated: "2023-11-05T18:53:19.566Z",
    name: "Stay.Plus Shanzu Duplex Apartment",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "28184",
    last_updated: "2023-11-05T18:53:19.566Z",
    name: "Stay.Plus Beach Road Apartment",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "28186",
    last_updated: "2023-11-05T18:53:19.566Z",
    name: "Elegant Furnished Homes",
    country: "KE",
    region: "Laikipia",
    city: "Laikipia",
    city_Id: "8071",
  },
  {
    hotel_id: "28187",
    last_updated: "2023-11-05T18:53:19.566Z",
    name: "Stay.Plus Pirates Beach Apartment",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "28188",
    last_updated: "2023-11-05T18:53:19.566Z",
    name: "Stay.Plus JKIA Studio Apartment",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28190",
    last_updated: "2023-11-05T18:53:19.566Z",
    name: "Stay.Plus Athiriver Family Apartment",
    country: "KE",
    region: "",
    city: "Machakos",
    city_Id: "8145",
  },
  {
    hotel_id: "28191",
    last_updated: "2023-11-05T18:53:19.566Z",
    name: "Stay.Plus Athiriver Garden Apartment",
    country: "KE",
    region: "",
    city: "Machakos",
    city_Id: "8145",
  },
  {
    hotel_id: "28192",
    last_updated: "2023-11-05T18:53:19.566Z",
    name: "Stay.Plus Karibu Homes Apartment",
    country: "KE",
    region: "",
    city: "Machakos",
    city_Id: "8145",
  },
  {
    hotel_id: "28193",
    last_updated: "2023-11-05T18:53:19.566Z",
    name: "Stay.Plus Donholm Bungalow",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28194",
    last_updated: "2023-11-05T18:53:19.566Z",
    name: "Sheywe Hotel Kakamega",
    country: "KE",
    region: "",
    city: "Kakamega",
    city_Id: "7995",
  },
  {
    hotel_id: "28196",
    last_updated: "2023-11-05T18:53:19.566Z",
    name: "Stay.Plus Milimani Apartment Kisumu",
    country: "KE",
    region: "Kisumu",
    city: "Kisumu",
    city_Id: "7410",
  },
  {
    hotel_id: "28211",
    last_updated: "2023-11-05T18:53:19.568Z",
    name: "Stay.Plus Balozi Court Apartment",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28212",
    last_updated: "2023-11-05T18:53:19.568Z",
    name: "Stay.Plus Lantana Suites Westlands",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28213",
    last_updated: "2023-11-05T18:53:19.568Z",
    name: "hotel",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28214",
    last_updated: "2023-11-05T18:53:19.568Z",
    name: "Stay.Plus Nakuru Luxury Villas",
    country: "KE",
    region: "",
    city: "Nakuru",
    city_Id: "4727",
  },
  {
    hotel_id: "28215",
    last_updated: "2023-11-05T18:53:19.568Z",
    name: "Harbour Key Cottages",
    country: "KE",
    region: "",
    city: "Kilifi",
    city_Id: "395",
  },
  {
    hotel_id: "28216",
    last_updated: "2023-11-05T18:53:19.568Z",
    name: "Stay.Plus Mideya Suites Kileleshwa",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "28217",
    last_updated: "2023-11-05T18:53:19.568Z",
    name: "Stay.Plus Prestigious Kileleshwa Apartment",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28218",
    last_updated: "2023-11-05T18:53:19.568Z",
    name: "Stay.Plus Hurlingham B&B Suite",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28219",
    last_updated: "2023-11-05T18:53:19.568Z",
    name: "Kenzo's Place on a Budget",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28220",
    last_updated: "2023-11-05T18:53:19.568Z",
    name: "Stay.Plus Milimani Apartment Suite Nakuru",
    country: "KE",
    region: "",
    city: "Nakuru",
    city_Id: "4727",
  },
  {
    hotel_id: "28222",
    last_updated: "2023-11-05T18:53:19.568Z",
    name: "Stay.Plus Kilimani Luxe Apartment",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28226",
    last_updated: "2023-11-05T18:53:19.570Z",
    name: "Stay.Plus Hidden Gems Apartments",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28227",
    last_updated: "2023-11-05T18:53:19.570Z",
    name: "Stay.Plus Kivulini Cottages Diani Beach",
    country: "KE",
    region: "Kwale",
    city: "Kwale",
    city_Id: "10853",
  },
  {
    hotel_id: "28228",
    last_updated: "2023-11-05T18:53:19.570Z",
    name: "Stay.Plus Nyali Luxury Apartments",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "28230",
    last_updated: "2023-11-05T18:53:19.570Z",
    name: "Stay.Plus Kiki Residences Nyali",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "28232",
    last_updated: "2023-11-05T18:53:19.570Z",
    name: "Stay.Plus Creek Residence Nyali",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "28236",
    last_updated: "2023-11-05T18:53:19.570Z",
    name: "Stay.Plus Lamera 1 Bedroom Apartment",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "28240",
    last_updated: "2023-11-05T18:53:19.570Z",
    name: "Stay.Plus Mpuuga Garden Apartment",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28241",
    last_updated: "2023-11-05T18:53:19.570Z",
    name: "Stay.Plus Lamera Garden View Cottage",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "28243",
    last_updated: "2023-11-05T18:53:19.570Z",
    name: "Stay.Plus Bustani studio Apartments Nyali",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "28244",
    last_updated: "2023-11-05T18:53:19.570Z",
    name: "Stay.Plus Sunset Paradise Apartments",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "28245",
    last_updated: "2023-11-05T18:53:19.570Z",
    name: "Stay.Plus Ngong Road Apartment",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28246",
    last_updated: "2023-11-05T18:53:19.570Z",
    name: "Stay.Plus Le Royal Penthouse",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "28247",
    last_updated: "2023-11-05T18:53:19.570Z",
    name: "Stay.Plus Bamburi Beachfront Apartment",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "28248",
    last_updated: "2023-11-05T18:53:19.570Z",
    name: "Stay.Plus Almasi Beachfront Apartments",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "28249",
    last_updated: "2023-11-05T18:53:19.570Z",
    name: "Stay.Plus Sunset Paradise 4 Bedroom Apartment",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "28250",
    last_updated: "2023-11-05T18:53:19.570Z",
    name: "Stay.Plus Mount Kenya Road Apartment Nyali",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "28251",
    last_updated: "2023-11-05T18:53:19.572Z",
    name: "Stay.Plus Royal Apartment Nyali",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "28252",
    last_updated: "2023-11-05T18:53:19.572Z",
    name: "Stay.Plus Naivasha Budget Apartment",
    country: "KE",
    region: "",
    city: "Nakuru",
    city_Id: "4727",
  },
  {
    hotel_id: "28254",
    last_updated: "2023-11-05T18:53:19.572Z",
    name: "Stay.Plus Ngara Executive Studio Apartment",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28255",
    last_updated: "2023-11-05T18:53:19.572Z",
    name: "Stay.Plus Nyali Luxury Suites",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "28257",
    last_updated: "2023-11-05T18:53:19.572Z",
    name: "Stay.Plus Kasarani Budget Apartment",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28258",
    last_updated: "2023-11-05T18:53:19.572Z",
    name: "Stay.Plus Mtwapa Holiday Maisonnette",
    country: "KE",
    region: "",
    city: "Kilifi",
    city_Id: "395",
  },
  {
    hotel_id: "28261",
    last_updated: "2023-11-05T18:53:19.572Z",
    name: "Stay.Plus TRM Apartments",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28266",
    last_updated: "2023-11-05T18:53:19.572Z",
    name: "Stay.Plus Nyandarua Road Apartment Nyali",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "28267",
    last_updated: "2023-11-05T18:53:19.572Z",
    name: "Stay.Plus Queens Apartment Nyali",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "28268",
    last_updated: "2023-11-05T18:53:19.572Z",
    name: "Stay.Plus Utawala Studio Apartment",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28269",
    last_updated: "2023-11-05T18:53:19.572Z",
    name: "Stay.Plus Seville Apartments Shanzu",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "28270",
    last_updated: "2023-11-05T18:53:19.572Z",
    name: "Stay.Plus Shree Link Star Apartment Nyali",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "28271",
    last_updated: "2023-11-05T18:53:19.572Z",
    name: "Stay.Plus Parklands 4 Bedroom Apartment",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28273",
    last_updated: "2023-11-05T18:53:19.574Z",
    name: "Stay.Plus 2 Bedroom Shree Apartment Nyali",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "28274",
    last_updated: "2023-11-05T18:53:19.574Z",
    name: "Stay.Plus Balozi Apartment South B",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28275",
    last_updated: "2023-11-05T18:53:19.574Z",
    name: "Stay.Plus Krish Start-Up Apartment Nyali",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "28278",
    last_updated: "2023-11-05T18:53:19.574Z",
    name: "Stay.Plus Marcus Apartment Kilimani",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28279",
    last_updated: "2023-11-05T18:53:19.574Z",
    name: "Stay.Plus Wood Avenue Apartment Kilimani",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28280",
    last_updated: "2023-11-05T18:53:19.574Z",
    name: "Stay.Plus Furaha Apartment Nyali",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "28281",
    last_updated: "2023-11-05T18:53:19.574Z",
    name: "Stay.Plus Mirema Drive Apartment",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28283",
    last_updated: "2023-11-05T18:53:19.574Z",
    name: "Stay.Plus Kasarani Apartment",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28287",
    last_updated: "2023-11-05T18:53:19.574Z",
    name: "Stay.Plus Elly's Studio Apartments South B",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28288",
    last_updated: "2023-11-05T18:53:19.574Z",
    name: "Stay.Plus Kingstone Apartment",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28291",
    last_updated: "2023-11-05T18:53:19.574Z",
    name: "Stay.Plus Vescon Apartment Bamburi",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "28292",
    last_updated: "2023-11-05T18:53:19.574Z",
    name: "Stay.Plus First Choice Family Apartment",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28293",
    last_updated: "2023-11-05T18:53:19.574Z",
    name: "Stay.Plus Lumumba Drive Studio Apartment",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28294",
    last_updated: "2023-11-05T18:53:19.574Z",
    name: "Stay.Plus Dennis Garden Apartment",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28295",
    last_updated: "2023-11-05T18:53:19.574Z",
    name: "Stay.Plus Kasarani Deluxe Apartment",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28300",
    last_updated: "2023-11-05T18:53:19.575Z",
    name: "Greenwoods Resort and Campsite",
    country: "KE",
    region: "",
    city: "Uasin Gishu",
    city_Id: "8022",
  },
  {
    hotel_id: "28301",
    last_updated: "2023-11-05T18:53:19.575Z",
    name: "GraceHouse Resort",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28302",
    last_updated: "2023-11-05T18:53:19.575Z",
    name: "Enzi Heights Apartments",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28303",
    last_updated: "2023-11-05T18:53:19.575Z",
    name: "PEC Guest House",
    country: "KE",
    region: "",
    city: "Kajiado",
    city_Id: "3563",
  },
  {
    hotel_id: "28306",
    last_updated: "2023-11-05T18:53:19.575Z",
    name: "Mukoma Heights",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28308",
    last_updated: "2023-11-05T18:53:19.575Z",
    name: "Garden of Favour Resort",
    country: "KE",
    region: "",
    city: "Nakuru",
    city_Id: "4727",
  },
  {
    hotel_id: "28309",
    last_updated: "2023-11-05T18:53:19.575Z",
    name: "Ol Loika Cottage",
    country: "KE",
    region: "",
    city: "Nakuru",
    city_Id: "4727",
  },
  {
    hotel_id: "28310",
    last_updated: "2023-11-05T18:53:19.575Z",
    name: "Merys Lodge, Naivasha",
    country: "KE",
    region: "",
    city: "Nakuru",
    city_Id: "4727",
  },
  {
    hotel_id: "28311",
    last_updated: "2023-11-05T18:53:19.575Z",
    name: "Kamake Gardens",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28312",
    last_updated: "2023-11-05T18:53:19.576Z",
    name: "Hotel Central Park",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28313",
    last_updated: "2023-11-05T18:53:19.576Z",
    name: "Leopard View Hotel",
    country: "KE",
    region: "",
    city: "Nakuru",
    city_Id: "4727",
  },
  {
    hotel_id: "28314",
    last_updated: "2023-11-05T18:53:19.576Z",
    name: "Yard Valley Manor",
    country: "KE",
    region: "",
    city: "Nakuru",
    city_Id: "4727",
  },
  {
    hotel_id: "28315",
    last_updated: "2023-11-05T18:53:19.576Z",
    name: "Sean's Accommodation",
    country: "KE",
    region: "",
    city: "Kiambu",
    city_Id: "7991",
  },
  {
    hotel_id: "28316",
    last_updated: "2023-11-05T18:53:19.576Z",
    name: "Kwitu Safari Homestay",
    country: "KE",
    region: "",
    city: "Machakos",
    city_Id: "8145",
  },
  {
    hotel_id: "28317",
    last_updated: "2023-11-05T18:53:19.576Z",
    name: "Izaak Walton Inn",
    country: "KE",
    region: "Embu",
    city: "Embu",
    city_Id: "8002",
  },
  {
    hotel_id: "28318",
    last_updated: "2023-11-05T18:53:19.576Z",
    name: "Bells Guest House",
    country: "KE",
    region: "Nyandarua",
    city: "Nyandarua",
    city_Id: "11089",
  },
  {
    hotel_id: "28319",
    last_updated: "2023-11-05T18:53:19.576Z",
    name: "The Loop Guest House & Restaurant",
    country: "KE",
    region: "",
    city: "Uasin Gishu",
    city_Id: "8022",
  },
  {
    hotel_id: "28320",
    last_updated: "2023-11-05T18:53:19.579Z",
    name: "Eldoret Luxurious Furnished Homes",
    country: "KE",
    region: "",
    city: "Uasin Gishu",
    city_Id: "8022",
  },
  {
    hotel_id: "28321",
    last_updated: "2023-11-05T18:53:19.579Z",
    name: "Moonshine Walkers",
    country: "KE",
    region: "",
    city: "Uasin Gishu",
    city_Id: "8022",
  },
  {
    hotel_id: "28322",
    last_updated: "2023-11-05T18:53:19.579Z",
    name: "Serenade Hotel Nakuru",
    country: "KE",
    region: "",
    city: "Nakuru",
    city_Id: "4727",
  },
  {
    hotel_id: "28323",
    last_updated: "2023-11-05T18:53:19.579Z",
    name: "Premier Lodge",
    country: "KE",
    region: "Narok",
    city: "Narok",
    city_Id: "10828",
  },
  {
    hotel_id: "28324",
    last_updated: "2023-11-05T18:53:19.579Z",
    name: "Dakaka Lodge",
    country: "KE",
    region: "Narok",
    city: "Narok",
    city_Id: "10828",
  },
  {
    hotel_id: "28325",
    last_updated: "2023-11-05T18:53:19.579Z",
    name: "Skyline Hotel and Suites",
    country: "KE",
    region: "Narok",
    city: "Narok",
    city_Id: "10828",
  },
  {
    hotel_id: "28326",
    last_updated: "2023-11-05T18:53:19.579Z",
    name: "Anabas River Cottages",
    country: "KE",
    region: "",
    city: "Kajiado",
    city_Id: "3563",
  },
  {
    hotel_id: "28327",
    last_updated: "2023-11-05T18:53:19.579Z",
    name: "Kenmosa Gardens",
    country: "KE",
    region: "",
    city: "Uasin Gishu",
    city_Id: "8022",
  },
  {
    hotel_id: "28328",
    last_updated: "2023-11-05T18:53:19.579Z",
    name: "Serenity Resturant & Accomodation",
    country: "KE",
    region: "",
    city: "Uasin Gishu",
    city_Id: "8022",
  },
  {
    hotel_id: "28329",
    last_updated: "2023-11-05T18:53:19.579Z",
    name: "Jaybee's Resort",
    country: "KE",
    region: "",
    city: "Uasin Gishu",
    city_Id: "8022",
  },
  {
    hotel_id: "28330",
    last_updated: "2023-11-05T18:53:19.579Z",
    name: "Fountain Apartments",
    country: "KE",
    region: "",
    city: "Nakuru",
    city_Id: "4727",
  },
  {
    hotel_id: "28331",
    last_updated: "2023-11-05T18:53:19.579Z",
    name: "Oldonyo Sapuk Resort",
    country: "KE",
    region: "",
    city: "Kiambu",
    city_Id: "7991",
  },
  {
    hotel_id: "28350",
    last_updated: "2023-11-05T18:53:19.579Z",
    name: "Stay.Plus Lamera seaview Cottage shanzu",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "28360",
    last_updated: "2023-11-05T18:53:19.579Z",
    name: "Stay.Plus Coral Apartment Nyali",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "28361",
    last_updated: "2023-11-05T18:53:19.579Z",
    name: "Stay.Plus S N J Apartments Nyali",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "28366",
    last_updated: "2023-11-05T18:53:19.579Z",
    name: "Stay.Plus Lukiki Apartment Nyali",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "28372",
    last_updated: "2023-11-05T18:53:19.579Z",
    name: "Mayas Residence",
    country: "KE",
    region: "",
    city: "Kajiado",
    city_Id: "3563",
  },
  {
    hotel_id: "28534",
    last_updated: "2023-11-05T18:53:19.581Z",
    name: "Silent Lodge",
    country: "KE",
    region: "Nyeri",
    city: "Nyeri",
    city_Id: "7947",
  },
  {
    hotel_id: "28880",
    last_updated: "2023-11-05T18:53:19.609Z",
    name: "Fahari Palace Serviced Apartments",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28882",
    last_updated: "2023-11-05T18:53:19.610Z",
    name: "Nairobi Transit Hotel",
    country: "KE",
    region: "Nairobi",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28883",
    last_updated: "2023-11-05T18:53:19.610Z",
    name: "Urban Point Hotel",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28884",
    last_updated: "2023-11-05T18:53:19.610Z",
    name: "Ash White Hotel",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28885",
    last_updated: "2023-11-05T18:53:19.610Z",
    name: "Pendeza la Casa",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28886",
    last_updated: "2023-11-05T18:53:19.610Z",
    name: "Oilepo B&B",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28887",
    last_updated: "2023-11-05T18:53:19.610Z",
    name: "Cool Rivers Hotel Ltd",
    country: "KE",
    region: "",
    city: "Nakuru",
    city_Id: "4727",
  },
  {
    hotel_id: "28888",
    last_updated: "2023-11-05T18:53:19.610Z",
    name: "Golden Palace Hotel",
    country: "KE",
    region: "",
    city: "Nakuru",
    city_Id: "4727",
  },
  {
    hotel_id: "28889",
    last_updated: "2023-11-05T18:53:19.610Z",
    name: "Lotos Inn and Suites Hotel",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28890",
    last_updated: "2023-11-05T18:53:19.610Z",
    name: "Eden Nairobi",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28891",
    last_updated: "2023-11-05T18:53:19.610Z",
    name: "Cozy Room JKIA",
    country: "KE",
    region: "",
    city: "Machakos",
    city_Id: "8145",
  },
  {
    hotel_id: "28892",
    last_updated: "2023-11-05T18:53:19.610Z",
    name: "Upperhill View Hotel",
    country: "KE",
    region: "",
    city: "Busia",
    city_Id: "8757",
  },
  {
    hotel_id: "28893",
    last_updated: "2023-11-05T18:53:19.610Z",
    name: "Flavours Lodge",
    country: "KE",
    region: "",
    city: "Nakuru",
    city_Id: "4727",
  },
  {
    hotel_id: "28894",
    last_updated: "2023-11-05T18:53:19.610Z",
    name: "Genesis Guest House",
    country: "KE",
    region: "",
    city: "Nakuru",
    city_Id: "4727",
  },
  {
    hotel_id: "28895",
    last_updated: "2023-11-05T18:53:19.610Z",
    name: "Old Station Lounge Naivasha",
    country: "KE",
    region: "",
    city: "Nakuru",
    city_Id: "4727",
  },
  {
    hotel_id: "28896",
    last_updated: "2023-11-05T18:53:19.610Z",
    name: "Faraja Guest House",
    country: "KE",
    region: "",
    city: "Nakuru",
    city_Id: "4727",
  },
  {
    hotel_id: "28897",
    last_updated: "2023-11-05T18:53:19.610Z",
    name: "Kabarak University Guesthouse and Conference Centre",
    country: "KE",
    region: "",
    city: "Baringo",
    city_Id: "8758",
  },
  {
    hotel_id: "28898",
    last_updated: "2023-11-05T18:53:19.610Z",
    name: "Eagle Palace Hotel",
    country: "KE",
    region: "",
    city: "Nakuru",
    city_Id: "4727",
  },
  {
    hotel_id: "28899",
    last_updated: "2023-11-05T18:53:19.610Z",
    name: "Sakina Hotel & Restaurant",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28900",
    last_updated: "2023-11-05T18:53:19.610Z",
    name: "Simani Guest House",
    country: "KE",
    region: "",
    city: "Kajiado",
    city_Id: "3563",
  },
  {
    hotel_id: "28901",
    last_updated: "2023-11-05T18:53:19.610Z",
    name: "Dreamland Hotel",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28903",
    last_updated: "2023-11-05T18:53:19.612Z",
    name: "Red Buffalo House",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28904",
    last_updated: "2023-11-05T18:53:19.612Z",
    name: "United Kenya Club",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28905",
    last_updated: "2023-11-05T18:53:19.612Z",
    name: "Damuku Guest House - Nairobi",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28906",
    last_updated: "2023-11-05T18:53:19.612Z",
    name: "Naivasha Rocky Eco Lodge (Rocky Resort)",
    country: "KE",
    region: "",
    city: "Nakuru",
    city_Id: "4727",
  },
  {
    hotel_id: "28907",
    last_updated: "2023-11-05T18:53:19.612Z",
    name: "Morendat Training and Conference Centre,Naivasha",
    country: "KE",
    region: "",
    city: "Nakuru",
    city_Id: "4727",
  },
  {
    hotel_id: "28908",
    last_updated: "2023-11-05T18:53:19.612Z",
    name: "Sunvasha hotel",
    country: "KE",
    region: "",
    city: "Nakuru",
    city_Id: "4727",
  },
  {
    hotel_id: "28909",
    last_updated: "2023-11-05T18:53:19.612Z",
    name: "Jax Safari Hotel",
    country: "KE",
    region: "",
    city: "Nakuru",
    city_Id: "4727",
  },
  {
    hotel_id: "28910",
    last_updated: "2023-11-05T18:53:19.612Z",
    name: "Bermuda Gardens",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28911",
    last_updated: "2023-11-05T18:53:19.612Z",
    name: "Vela Serviced Suite Apartments",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28912",
    last_updated: "2023-11-05T18:53:19.612Z",
    name: "Megg's Place Kisumu",
    country: "KE",
    region: "",
    city: "Kakamega",
    city_Id: "7995",
  },
  {
    hotel_id: "28914",
    last_updated: "2023-11-05T18:53:19.612Z",
    name: "Sooper Guest House",
    country: "KE",
    region: "Kisumu",
    city: "Kisumu",
    city_Id: "7410",
  },
  {
    hotel_id: "28915",
    last_updated: "2023-11-05T18:53:19.612Z",
    name: "Balance Park Hotel",
    country: "KE",
    region: "Vihiga",
    city: "Vihiga",
    city_Id: "11356",
  },
  {
    hotel_id: "28916",
    last_updated: "2023-11-05T18:53:19.612Z",
    name: "Samwell 210 Apartment",
    country: "KE",
    region: "Vihiga",
    city: "Vihiga",
    city_Id: "11356",
  },
  {
    hotel_id: "28917",
    last_updated: "2023-11-05T18:53:19.612Z",
    name: "Mona Lisa Guest House Kisumu",
    country: "KE",
    region: "Kisumu",
    city: "Kisumu",
    city_Id: "7410",
  },
  {
    hotel_id: "28918",
    last_updated: "2023-11-05T18:53:19.612Z",
    name: "Chambai Safari Hotel",
    country: "KE",
    region: "",
    city: "Nakuru",
    city_Id: "4727",
  },
  {
    hotel_id: "28919",
    last_updated: "2023-11-05T18:53:19.612Z",
    name: "Rockwel Hotel",
    country: "KE",
    region: "Kisumu",
    city: "Kisumu",
    city_Id: "7410",
  },
  {
    hotel_id: "28920",
    last_updated: "2023-11-05T18:53:19.612Z",
    name: "Cold Springs Boutique Hotel - Karen",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28921",
    last_updated: "2023-11-05T18:53:19.612Z",
    name: "Cold Springs Hotel - Homabay",
    country: "KE",
    region: "Homa Bay",
    city: "Homa Bay",
    city_Id: "8021",
  },
  {
    hotel_id: "28922",
    last_updated: "2023-11-05T18:53:19.612Z",
    name: "Rolf's Place",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28923",
    last_updated: "2023-11-05T18:53:19.614Z",
    name: "Inks Hotel",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28925",
    last_updated: "2023-11-05T18:53:19.614Z",
    name: "Cordial Bed and Breakfast",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28926",
    last_updated: "2023-11-05T18:53:19.614Z",
    name: "Khadz Nest",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28927",
    last_updated: "2023-11-05T18:53:19.614Z",
    name: "Karibu Airport Homestay",
    country: "KE",
    region: "",
    city: "Machakos",
    city_Id: "8145",
  },
  {
    hotel_id: "28928",
    last_updated: "2023-11-05T18:53:19.614Z",
    name: "Elegant Comfy Apartment",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28930",
    last_updated: "2023-11-05T18:53:19.614Z",
    name: "Sunyee Cozy Studio Apartments",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28931",
    last_updated: "2023-11-05T18:53:19.614Z",
    name: "New Carnation Hotel",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28932",
    last_updated: "2023-11-05T18:53:19.614Z",
    name: "City Wall Hotel",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28933",
    last_updated: "2023-11-05T18:53:19.614Z",
    name: "Decale Palace Hotel",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28934",
    last_updated: "2023-11-05T18:53:19.614Z",
    name: "Athi Holiday Resort",
    country: "KE",
    region: "",
    city: "Machakos",
    city_Id: "8145",
  },
  {
    hotel_id: "28935",
    last_updated: "2023-11-05T18:53:19.614Z",
    name: "The Lawns Restaurant, Nakuru",
    country: "KE",
    region: "",
    city: "Nakuru",
    city_Id: "4727",
  },
  {
    hotel_id: "28936",
    last_updated: "2023-11-05T18:53:19.614Z",
    name: "Seravic Hotel Nakuru",
    country: "KE",
    region: "",
    city: "Nakuru",
    city_Id: "4727",
  },
  {
    hotel_id: "28937",
    last_updated: "2023-11-05T18:53:19.614Z",
    name: "Porcupine Guest House",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28938",
    last_updated: "2023-11-05T18:53:19.614Z",
    name: "Brian's House",
    country: "KE",
    region: "",
    city: "Nakuru",
    city_Id: "4727",
  },
  {
    hotel_id: "28939",
    last_updated: "2023-11-05T18:53:19.614Z",
    name: "Sandalwood Suites Nakuru",
    country: "KE",
    region: "",
    city: "Nakuru",
    city_Id: "4727",
  },
  {
    hotel_id: "28940",
    last_updated: "2023-11-05T18:53:19.614Z",
    name: "Sunbird Lodge",
    country: "KE",
    region: "Narok",
    city: "Narok",
    city_Id: "10828",
  },
  {
    hotel_id: "28941",
    last_updated: "2023-11-05T18:53:19.614Z",
    name: "Pekers Lodge",
    country: "KE",
    region: "",
    city: "Meru",
    city_Id: "8003",
  },
  {
    hotel_id: "28942",
    last_updated: "2023-11-05T18:53:19.614Z",
    name: "Elmer Resort & Spa Naivasha",
    country: "KE",
    region: "Nyandarua",
    city: "Nyandarua",
    city_Id: "11089",
  },
  {
    hotel_id: "28943",
    last_updated: "2023-11-05T18:53:19.614Z",
    name: "Goshen Inn Eldoret",
    country: "KE",
    region: "",
    city: "Uasin Gishu",
    city_Id: "8022",
  },
  {
    hotel_id: "28944",
    last_updated: "2023-11-05T18:53:19.616Z",
    name: "Eldopalm Guesthouse",
    country: "KE",
    region: "",
    city: "Uasin Gishu",
    city_Id: "8022",
  },
  {
    hotel_id: "28945",
    last_updated: "2023-11-05T18:53:19.616Z",
    name: "2D La Office palace",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28946",
    last_updated: "2023-11-05T18:53:19.616Z",
    name: "Kings And Queens Grill And Bistro",
    country: "KE",
    region: "Vihiga",
    city: "Vihiga",
    city_Id: "11356",
  },
  {
    hotel_id: "28947",
    last_updated: "2023-11-05T18:53:19.616Z",
    name: "The Pyramid Restaurant",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "28948",
    last_updated: "2023-11-05T18:53:19.616Z",
    name: "Symphony Hotel",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "28949",
    last_updated: "2023-11-05T18:53:19.616Z",
    name: "Rift Valley Hills Resort",
    country: "KE",
    region: "",
    city: "Baringo",
    city_Id: "8758",
  },
  {
    hotel_id: "28950",
    last_updated: "2023-11-05T18:53:19.616Z",
    name: "Amboseli Getaway Hotel",
    country: "KE",
    region: "",
    city: "Kajiado",
    city_Id: "3563",
  },
  {
    hotel_id: "28951",
    last_updated: "2023-11-05T18:53:19.616Z",
    name: "Eden Bliss Gardens",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28952",
    last_updated: "2023-11-05T18:53:19.616Z",
    name: "Centurion Hotel",
    country: "KE",
    region: "",
    city: "Kiambu",
    city_Id: "7991",
  },
  {
    hotel_id: "28953",
    last_updated: "2023-11-05T18:53:19.616Z",
    name: "Monalisa Hotel Thika",
    country: "KE",
    region: "",
    city: "Kiambu",
    city_Id: "7991",
  },
  {
    hotel_id: "28954",
    last_updated: "2023-11-05T18:53:19.616Z",
    name: "Dove House",
    country: "KE",
    region: "Muranga",
    city: "Muranga",
    city_Id: "8139",
  },
  {
    hotel_id: "28955",
    last_updated: "2023-11-05T18:53:19.616Z",
    name: "Royal Pine Cottages",
    country: "KE",
    region: "",
    city: "Kiambu",
    city_Id: "7991",
  },
  {
    hotel_id: "28956",
    last_updated: "2023-11-05T18:53:19.616Z",
    name: "Watamu Adventist Beach Resort",
    country: "KE",
    region: "",
    city: "Kilifi",
    city_Id: "395",
  },
  {
    hotel_id: "28957",
    last_updated: "2023-11-05T18:53:19.616Z",
    name: "Velvet Hotel and Restaurant",
    country: "KE",
    region: "",
    city: "Kiambu",
    city_Id: "7991",
  },
  {
    hotel_id: "28958",
    last_updated: "2023-11-05T18:53:19.616Z",
    name: "Imperial Guest House",
    country: "KE",
    region: "Nyeri",
    city: "Nyeri",
    city_Id: "7947",
  },
  {
    hotel_id: "28959",
    last_updated: "2023-11-05T18:53:19.616Z",
    name: "Lukee Guest House",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28960",
    last_updated: "2023-11-05T18:53:19.616Z",
    name: "Peak Leaders Hotel, Mweiga",
    country: "KE",
    region: "Nyandarua",
    city: "Nyandarua",
    city_Id: "11089",
  },
  {
    hotel_id: "28961",
    last_updated: "2023-11-05T18:53:19.616Z",
    name: "The Hotel Ezri",
    country: "KE",
    region: "",
    city: "Meru",
    city_Id: "8003",
  },
  {
    hotel_id: "28962",
    last_updated: "2023-11-05T18:53:19.616Z",
    name: "Maryland Hotel",
    country: "KE",
    region: "Nyeri",
    city: "Nyeri",
    city_Id: "7947",
  },
  {
    hotel_id: "28963",
    last_updated: "2023-11-05T18:53:19.616Z",
    name: "Da Venue Resort",
    country: "KE",
    region: "Nyeri",
    city: "Nyeri",
    city_Id: "7947",
  },
  {
    hotel_id: "28964",
    last_updated: "2023-11-05T18:53:19.618Z",
    name: "Tango Hotel",
    country: "KE",
    region: "Nyandarua",
    city: "Nyandarua",
    city_Id: "11089",
  },
  {
    hotel_id: "28965",
    last_updated: "2023-11-05T18:53:19.618Z",
    name: "Express Inn",
    country: "KE",
    region: "Nyeri",
    city: "Nyeri",
    city_Id: "7947",
  },
  {
    hotel_id: "28966",
    last_updated: "2023-11-05T18:53:19.618Z",
    name: "Lerruat Log Resort",
    country: "KE",
    region: "",
    city: "Kajiado",
    city_Id: "3563",
  },
  {
    hotel_id: "28968",
    last_updated: "2023-11-05T18:53:19.618Z",
    name: "Savannah Paradise Hotel",
    country: "KE",
    region: "",
    city: "Kajiado",
    city_Id: "3563",
  },
  {
    hotel_id: "28969",
    last_updated: "2023-11-05T18:53:19.618Z",
    name: "Ndanga International",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "28993",
    last_updated: "2023-11-05T18:53:19.618Z",
    name: "Johari Villas",
    country: "KE",
    region: "",
    city: "Kilifi",
    city_Id: "395",
  },
  {
    hotel_id: "28994",
    last_updated: "2023-11-05T18:53:19.618Z",
    name: "Honey Dew Stays",
    country: "KE",
    region: "",
    city: "Machakos",
    city_Id: "8145",
  },
  {
    hotel_id: "35683",
    last_updated: "2023-11-05T18:53:19.722Z",
    name: "Hotel Boulevard",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "35797",
    last_updated: "2023-11-05T18:53:19.724Z",
    name: "Sarova Panafric",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "35798",
    last_updated: "2023-11-05T18:53:19.724Z",
    name: "Sarova Stanley",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "35800",
    last_updated: "2023-11-05T18:53:19.724Z",
    name: "Sarova Woodlands Hotel and Spa",
    country: "KE",
    region: "",
    city: "Nakuru",
    city_Id: "4727",
  },
  {
    hotel_id: "35801",
    last_updated: "2023-11-05T18:53:19.724Z",
    name: "Sarova Whitesands Beach Resort & Spa",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "35802",
    last_updated: "2023-11-05T18:53:19.724Z",
    name: "Sarova Mara Game Camp",
    country: "KE",
    region: "Narok",
    city: "Narok",
    city_Id: "10828",
  },
  {
    hotel_id: "35803",
    last_updated: "2023-11-05T18:53:19.724Z",
    name: "Sarova Shaba Game Lodge",
    country: "KE",
    region: "Isiolo",
    city: "Isiolo",
    city_Id: "7950",
  },
  {
    hotel_id: "35804",
    last_updated: "2023-11-05T18:53:19.724Z",
    name: "Sarova Lion Hill Game Lodge",
    country: "KE",
    region: "",
    city: "Nakuru",
    city_Id: "4727",
  },
  {
    hotel_id: "38288",
    last_updated: "2023-11-05T18:53:19.761Z",
    name: "Vintage Chic Apartment",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "38406",
    last_updated: "2023-11-05T18:53:19.761Z",
    name: "Pearls and Corals Resort",
    country: "KE",
    region: "",
    city: "Kilifi",
    city_Id: "395",
  },
  {
    hotel_id: "38411",
    last_updated: "2023-11-05T18:53:19.761Z",
    name: "Kings Premier Inn",
    country: "KE",
    region: "",
    city: "Machakos",
    city_Id: "8145",
  },
  {
    hotel_id: "38412",
    last_updated: "2023-11-05T18:53:19.761Z",
    name: "Nguge Hills Lodge & Wellness Retreat",
    country: "KE",
    region: "Siaya",
    city: "Siaya",
    city_Id: "8092",
  },
  {
    hotel_id: "38416",
    last_updated: "2023-11-05T18:53:19.761Z",
    name: "Gigiri Lion Villas",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "38417",
    last_updated: "2023-11-05T18:53:19.761Z",
    name: "The Hydeout Riviera",
    country: "KE",
    region: "Kisumu",
    city: "Kisumu",
    city_Id: "7410",
  },
  {
    hotel_id: "38418",
    last_updated: "2023-11-05T18:53:19.761Z",
    name: "Limpopo Hotel Suites",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "38420",
    last_updated: "2023-11-05T18:53:19.761Z",
    name: "Edgewood Country Place",
    country: "KE",
    region: "",
    city: "Kiambu",
    city_Id: "7991",
  },
  {
    hotel_id: "38464",
    last_updated: "2023-11-05T18:53:19.763Z",
    name: "Whirlspring Hotel Kisumu",
    country: "KE",
    region: "",
    city: "Kisumu",
    city_Id: "7410",
  },
  {
    hotel_id: "38465",
    last_updated: "2023-11-05T18:53:19.763Z",
    name: "Nairobi Glory Palace Hotel",
    country: "KE",
    region: "Nairobi",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "38468",
    last_updated: "2023-11-05T18:53:19.763Z",
    name: "Qaribu Inn",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "38476",
    last_updated: "2023-11-05T18:53:19.763Z",
    name: "Joy Palace Hotel Nairobi",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "38477",
    last_updated: "2023-11-05T18:53:19.765Z",
    name: "Deuts Four Seasons Hotel",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "52015",
    last_updated: "2023-11-05T18:53:19.826Z",
    name: "Rock and Sea Resort",
    country: "KE",
    region: "Kilifi",
    city: "Kilifi",
    city_Id: "395",
  },
  {
    hotel_id: "52407",
    last_updated: "2023-11-05T18:53:19.832Z",
    name: "Sentrim Amboseli ",
    country: "KE",
    region: "kimana",
    city: "Kimana, Kajiado",
    city_Id: "12773",
  },
  {
    hotel_id: "52409",
    last_updated: "2023-11-05T18:53:19.832Z",
    name: "Sentrim Tsavo East",
    country: "KE",
    region: "gutini",
    city: "Uasin Gishu",
    city_Id: "8022",
  },
  {
    hotel_id: "52410",
    last_updated: "2023-11-05T18:53:19.832Z",
    name: "Sentrim Castle Royal Hotel Mombasa",
    country: "KE",
    region: "MOMBASA",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "52411",
    last_updated: "2023-11-05T18:53:19.832Z",
    name: "Sentrim Mara",
    country: "KE",
    region: "kenya",
    city: "Narok",
    city_Id: "10828",
  },
  {
    hotel_id: "52413",
    last_updated: "2023-11-05T18:53:19.832Z",
    name: "Sentrim Elementaita",
    country: "KE",
    region: "kanyawa",
    city: "Nakuru",
    city_Id: "4727",
  },
  {
    hotel_id: "53647",
    last_updated: "2023-11-05T18:53:19.849Z",
    name: "Park Inn by Radisson, Nairobi Westlands",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "57093",
    last_updated: "2023-11-05T18:53:19.878Z",
    name: "Voyager Beach Resort",
    country: "KE",
    region: "Kilifi",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "58951",
    last_updated: "2023-11-05T18:53:19.897Z",
    name: "Diani Palm Resort",
    country: "KE",
    region: "Coastal Kenya",
    city: "Ukunda",
    city_Id: "13559",
  },
  {
    hotel_id: "60841",
    last_updated: "2023-11-05T18:53:19.962Z",
    name: "Brickwood Suites",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "60905",
    last_updated: "2023-11-05T18:53:19.962Z",
    name: "The Drexel House",
    country: "KE",
    region: "Nairobi",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "61731",
    last_updated: "2023-11-05T18:53:19.970Z",
    name: "The Great Rift Valley Lodge & Golf Resort",
    country: "KE",
    region: "Nakuru County ",
    city: "Naivasha",
    city_Id: "7631",
  },
  {
    hotel_id: "68543",
    last_updated: "2023-11-05T18:53:20.010Z",
    name: "Lukenya Motorcross",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "70064",
    last_updated: "2023-11-05T18:53:20.048Z",
    name: "Stedmak Mtwapa",
    country: "KE",
    region: "Mombasa",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "70066",
    last_updated: "2023-11-05T18:53:20.048Z",
    name: "Roddy Hotel Kisumu",
    country: "KE",
    region: "Nyanza",
    city: "Kisumu",
    city_Id: "7410",
  },
  {
    hotel_id: "70067",
    last_updated: "2023-11-05T18:53:20.048Z",
    name: "Mishki Homes",
    country: "KE",
    region: "Coastal Kenya",
    city: "Malindi",
    city_Id: "8010",
  },
  {
    hotel_id: "70068",
    last_updated: "2023-11-05T18:53:20.048Z",
    name: "River Lumi Resort",
    country: "KE",
    region: "",
    city: "Taita-Taveta",
    city_Id: "8090",
  },
  {
    hotel_id: "70069",
    last_updated: "2023-11-05T18:53:20.048Z",
    name: "Lake Elementaita Villas",
    country: "KE",
    region: "Nakuru",
    city: "Nakuru",
    city_Id: "4727",
  },
  {
    hotel_id: "70070",
    last_updated: "2023-11-05T18:53:20.048Z",
    name: "Sue Casa Apartment",
    country: "KE",
    region: "Coastal Kenya",
    city: "Mtwapa",
    city_Id: "14555",
  },
  {
    hotel_id: "70071",
    last_updated: "2023-11-05T18:53:20.048Z",
    name: "Ngara Riverside Lodge",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "70086",
    last_updated: "2023-11-05T18:53:20.049Z",
    name: "Levana Hotel",
    country: "KE",
    region: "",
    city: "Kitengela",
    city_Id: "14560",
  },
  {
    hotel_id: "70087",
    last_updated: "2023-11-05T18:53:20.049Z",
    name: "Heritage Breeze Hotel",
    country: "KE",
    region: "",
    city: "Nakuru",
    city_Id: "4727",
  },
  {
    hotel_id: "70088",
    last_updated: "2023-11-05T18:53:20.049Z",
    name: "The VIP Luxury Lounge",
    country: "KE",
    region: "",
    city: "Kisumu",
    city_Id: "7410",
  },
  {
    hotel_id: "70089",
    last_updated: "2023-11-05T18:53:20.049Z",
    name: "Govan Laikipia Farm",
    country: "KE",
    region: "Laikipia County",
    city: "Nanyuki, Laikipia",
    city_Id: "13933",
  },
  {
    hotel_id: "70090",
    last_updated: "2023-11-05T18:53:20.049Z",
    name: "La Beila Hotel Meru",
    country: "KE",
    region: "",
    city: "Meru",
    city_Id: "8003",
  },
  {
    hotel_id: "71131",
    last_updated: "2023-11-05T18:53:20.065Z",
    name: "KenGen Furnished and Serviced Apartments",
    country: "KE",
    region: "Nairobi",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "71133",
    last_updated: "2023-11-05T18:53:20.065Z",
    name: "Favor Furnished Apartments",
    country: "KE",
    region: "Coastal Kenya",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "71139",
    last_updated: "2023-11-05T18:53:20.065Z",
    name: "West Lerruat Hotel",
    country: "KE",
    region: "Nairobi",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "71141",
    last_updated: "2023-11-05T18:53:20.065Z",
    name: "Toque Hotel",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "71148",
    last_updated: "2023-11-05T18:53:20.065Z",
    name: "Sigona Golf Club",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "71193",
    last_updated: "2023-11-05T18:53:20.066Z",
    name: "CySuites Apartment Hotel",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "71202",
    last_updated: "2023-11-05T18:53:20.067Z",
    name: "Three Rivers Resort Maragua",
    country: "KE",
    region: "Murang'a County",
    city: "Thika",
    city_Id: "14723",
  },
  {
    hotel_id: "71205",
    last_updated: "2023-11-05T18:53:20.067Z",
    name: "Lenana Suites",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "71213",
    last_updated: "2023-11-05T18:53:20.067Z",
    name: "Marrkson Suites",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "71221",
    last_updated: "2023-11-05T18:53:20.067Z",
    name: "Takeover Restaurant & Accommodation",
    country: "KE",
    region: "",
    city: "Kisumu",
    city_Id: "7410",
  },
  {
    hotel_id: "71223",
    last_updated: "2023-11-05T18:53:20.067Z",
    name: "Radix Hotel",
    country: "KE",
    region: "Nairobi",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "71224",
    last_updated: "2023-11-05T18:53:20.067Z",
    name: "Stone Camp Naivasha",
    country: "KE",
    region: "",
    city: "Nakuru",
    city_Id: "4727",
  },
  {
    hotel_id: "71226",
    last_updated: "2023-11-05T18:53:20.068Z",
    name: "Prime Regent Hotel",
    country: "KE",
    region: "Meru County",
    city: "Nkubu",
    city_Id: "14725",
  },
  {
    hotel_id: "71233",
    last_updated: "2023-11-05T18:53:20.068Z",
    name: "Eton Hotel",
    country: "KE",
    region: "Kiambu",
    city: "Thika",
    city_Id: "14723",
  },
  {
    hotel_id: "71234",
    last_updated: "2023-11-05T18:53:20.068Z",
    name: "Space International Hotel",
    country: "KE",
    region: "Nairobi",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "71310",
    last_updated: "2023-11-05T18:53:20.068Z",
    name: "A&L Hotel",
    country: "KE",
    region: "",
    city: "Machakos",
    city_Id: "8145",
  },
  {
    hotel_id: "71648",
    last_updated: "2023-11-05T18:53:20.078Z",
    name: "Mesita Classic Resort, Kilifi",
    country: "KE",
    region: "Kilifi",
    city: "Malindi",
    city_Id: "8010",
  },
  {
    hotel_id: "71649",
    last_updated: "2023-11-05T18:53:20.078Z",
    name: "Louis Montfort Grand Resort, Mombasa",
    country: "KE",
    region: "Kilifi",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "71653",
    last_updated: "2023-11-05T18:53:20.078Z",
    name: "Machakos Inn Hotel",
    country: "KE",
    region: "Machakos",
    city: "Machakos",
    city_Id: "8145",
  },
  {
    hotel_id: "71669",
    last_updated: "2023-11-05T18:53:20.078Z",
    name: "Waffles Suites",
    country: "KE",
    region: "Nakuru County ",
    city: "Nakuru",
    city_Id: "4727",
  },
  {
    hotel_id: "71670",
    last_updated: "2023-11-05T18:53:20.078Z",
    name: "Peacock Court Hotel, Kilifi",
    country: "KE",
    region: "Kilifi",
    city: "Kilifi",
    city_Id: "395",
  },
  {
    hotel_id: "71672",
    last_updated: "2023-11-05T18:53:20.078Z",
    name: "Stedmak Gardens Karen",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "71680",
    last_updated: "2023-11-05T18:53:20.078Z",
    name: "Eldon Suites & Apartments",
    country: "KE",
    region: "",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "71723",
    last_updated: "2023-11-05T18:53:20.078Z",
    name: "Lagoon Resort & Spa",
    country: "KE",
    region: "Kirinyaga",
    city: "Kirinyaga",
    city_Id: "8595",
  },
  {
    hotel_id: "71749",
    last_updated: "2023-11-05T18:53:20.080Z",
    name: "Machakos Inn Hotel",
    country: "KE",
    region: "Machakos County",
    city: "Machakos",
    city_Id: "8145",
  },
  {
    hotel_id: "71750",
    last_updated: "2023-11-05T18:53:20.080Z",
    name: "Louis Montfort Grand Resort",
    country: "KE",
    region: "Kenya",
    city: "Nyali",
    city_Id: "7632",
  },
  {
    hotel_id: "71845",
    last_updated: "2023-11-05T18:53:20.082Z",
    name: "Purple Haze Apartments",
    country: "KE",
    region: "Nairobi",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "71956",
    last_updated: "2023-11-05T18:53:20.085Z",
    name: "Santori Villas, Shanzu, Mombasa",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "72261",
    last_updated: "2023-11-05T18:53:20.095Z",
    name: "Hotel Sapphire",
    country: "KE",
    region: "",
    city: "Mombasa",
    city_Id: "3438",
  },
  {
    hotel_id: "72522",
    last_updated: "2023-11-05T18:53:20.112Z",
    name: "Residence Inn By Mahanaim",
    country: "KE",
    region: "Kiambu",
    city: "Juja",
    city_Id: "14870",
  },
  {
    hotel_id: "73282",
    last_updated: "2023-11-05T18:53:20.137Z",
    name: "Stay.Plus Silverstone Apartments",
    country: "KE",
    region: "Nairobi",
    city: "Nairobi",
    city_Id: "532",
  },
  {
    hotel_id: "76343",
    last_updated: "2023-11-05T18:53:20.253Z",
    name: "Muthu Warwick Mount Kenya Hotel, Nanyuki",
    country: "KE",
    region: "Rift Valley",
    city: "Nanyuki",
    city_Id: "15381",
  },
];
