import React from "react";
import MpesaForm from "../forms/MpesaForm";
const paymentMethods = [
  {
    id: 1,
    name: "M-Pesa",
    image:
      "https://melodicamusicstores.com/wp-content/uploads/2020/07/lipa-na-mpesa.png",
    component: <MpesaForm />,
  },
];

export default paymentMethods;
