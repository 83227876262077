import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { serverUrl } from "../components/Controllers/Config";
import { useParams } from "react-router-dom";
import ImageGallery from "react-image-gallery";
import Room from "../components/widgets/Room";
import "../css/Booking.css";
import BookingForm from "../components/widgets/BookingForm";
import Map from "../components/widgets/Map";
import Accordion from "react-bootstrap/Accordion";

const Booking = () => {
  const navigate = useNavigate();
  const { propertyId } = useParams();
  const [propertyinfo, setPropertyInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const queryParams = new URLSearchParams(window.location.search);
  const name = queryParams.get("name");
  const checkIn = queryParams.get("checkIn");
  const nationality = queryParams.get("nationality");
  const nights = queryParams.get("nights");
  const guests = queryParams.get("guests");
  const [totalGuests, setTotalGuests] = useState(0);
  const [guestData, setGuestData] = useState([]);
  const [totalRooms, setTotalRooms] = useState(0);
  const [roomGuests, setRoomGuests] = useState([]);

  useEffect(() => {
    const calculateGuestData = () => {
      let totalGuests = 0;
      const roomData = guests.split(".");
      const guestData = roomData.map((room) => {
        const roomInfo = room.split("-");
        const adults = parseInt(roomInfo[0]);
        let childrenAges = [];
        if (roomInfo.length > 1) {
          childrenAges = roomInfo[1].split(",").map((age) => parseInt(age));
        }
        totalGuests += adults + childrenAges.length;
        return {
          adults: adults,
          children: childrenAges.length,
          childrenAges: childrenAges,
        };
      });

      const roomGuests = roomData.map((room) => {
        const roomInfo = room.split("-");
        const adults = parseInt(roomInfo[0]);
        let children = [];
        if (roomInfo.length > 1) {
          children = roomInfo[1].split(",").map((age) => parseInt(age));
        }
        return {
          adults: adults,
          children: children,
        };
      });

      console.log("totalguest", totalGuests);
      console.log("roomGuests", roomGuests);
      setTotalGuests(totalGuests);
      setGuestData(guestData);
      setRoomGuests(roomGuests);
    };

    const calculateTotalRooms = () => {
      const roomCounts = guests.split(".");
      console.log("totalrooms", roomCounts.length);
      setTotalRooms(roomCounts.length);
    };

    calculateGuestData();
    calculateTotalRooms();
  }, [guests]);

  const checkOut = new Date(checkIn);
  checkOut.setDate(checkOut.getDate() + parseInt(nights));
  const formattedCheckOut = checkOut.toISOString().split("T")[0];

  useEffect(() => {
    const fetchPropertyinfo = async () => {
      const requestData = {
        propertyId: propertyId,
        name: name,
        checkIn: checkIn,
        nationality: nationality,
        nights: nights,
        guests: guests,
      };

      try {
        const response = await fetch(
          `${serverUrl}/hyperguest/searchPropertyBooking`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestData),
          }
        );

        if (response.ok) {
          const data = await response.json();
          if (data.errorCode === "MP.500") {
            navigate("/");
          } else {
            setPropertyInfo(data);
          }
        } else {
          console.error("Error fetching propertyinfo");
        }
      } catch (error) {
        console.error("Error in the propertyinfo request:", error);
        navigate("/");
      } finally {
        setLoading(false);
      }
    };

    fetchPropertyinfo();
  }, [propertyId, navigate]);

  const groupFacilitiesByCategory = (facilities) => {
    const groupedFacilities = {};
    facilities.forEach((facility) => {
      const category = facility.category;
      if (!groupedFacilities[category]) {
        groupedFacilities[category] = [];
      }
      groupedFacilities[category].push(facility);
    });
    return Object.keys(groupedFacilities).map((category) => ({
      category: category,
      items: groupedFacilities[category],
    }));
  };

  if (loading) {
    return (
      <div className="text-center">
        <h3>Fetching Booking Data</h3>
        <p>Please wait...</p>
      </div>
    );
  }

  if (!propertyinfo) {
    navigate("/");
    return null;
  }

  const params = {
    checkIn: checkIn,
    checkOut: formattedCheckOut,
    nationality: nationality,
    propertyId: propertyId,
  };

  return (
    <>
      {propertyinfo && (
        <div className="container">
          <div>
            <div className="row p-2">
              <h2>{propertyinfo.propertyData.name}</h2>
              <div className="col-md-8 ">
                <p className="">
                  <i className="fas fa-map-marker-alt"></i>&nbsp;
                  {propertyinfo
                    ? propertyinfo.propertyData.location
                      ? propertyinfo.propertyData.location.address
                      : ""
                    : ""}
                </p>
              </div>
              <div className="col-md-4 ">
                <div className="">
                  {propertyinfo &&
                    propertyinfo.propertyData.settings &&
                    propertyinfo.propertyData.settings.hotelType && (
                      <span className="badge text-bg-dark">
                        {propertyinfo.propertyData.settings.hotelType.name}
                      </span>
                    )}
                  &nbsp;
                  {[...Array(propertyinfo?.rating || 0)].map((rating, i) => (
                    <i key={i} className="fas fa-star text-warning"></i>
                  ))}
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-12">
                <ImageGallery
                  items={propertyinfo.propertyData.images.map((image) => ({
                    original: image.uri,
                    thumbnail: image.uri,
                  }))}
                  thumbnailPosition={"bottom"}
                  autoPlay
                />
              </div>
              <div className="col-md-12">
                {propertyinfo && propertyinfo.propertyData.descriptions && (
                  <div>
                    {propertyinfo.propertyData.descriptions.map(
                      (description, index) => (
                        <p key={index} className="mt-2">
                          {description.description}
                        </p>
                      )
                    )}
                  </div>
                )}
              </div>
            </div>

            {propertyinfo.propertyData.rooms && (
              <div className="row">
                <h2>Make Reservation</h2>
                <div>
                  {propertyinfo.remarks &&
                    propertyinfo.remarks.map((remarkItem) => (
                      <p className="text-small">{remarkItem}</p>
                    ))}
                </div>
                <BookingForm
                  propertyinfo={propertyinfo}
                  totalRooms={totalRooms}
                  guestData={guestData}
                  params={params}
                  totalGuests={totalGuests}
                  roomGuests={roomGuests}
                />
              </div>
            )}
            <h2>Location</h2>
            <Map
              Lat={propertyinfo.propertyData.coordinates.latitude}
              Lng={propertyinfo.propertyData.coordinates.longitude}
            />
            <h2 className="mt-2">Facilities</h2>
            <Accordion>
              <div className="row mt-2">
                {groupFacilitiesByCategory(
                  propertyinfo.propertyData.facilities
                ).map((group, index) => (
                  <div key={index} className="col-md-4">
                    <Accordion.Item eventKey={index} className="mt-1">
                      <Accordion.Header>
                        <h5>{group.category}</h5>
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul className="list-group list-group-flush">
                          {group.items.map((facility, i) => (
                            <li key={i} className="list-group-item">
                              {facility.name}
                            </li>
                          ))}
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </div>
                ))}
              </div>
            </Accordion>
          </div>
        </div>
      )}
    </>
  );
};

export default Booking;
