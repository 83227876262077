// config.js
export const serverUrl = "https://server.kalielltd.co.ke/api";
export const appKey = "7525AB41CAE83DD7858314958DEAE";
export const placeholderImage = "/assets/placeholder.png";
export const MAPBOX_ACCESS_TOKEN =
  "pk.eyJ1IjoiY29sbGluc25qb2thIiwiYSI6ImNrbDdoMW9iMjBmc28ycG5wNGtleHY1eTIifQ.NtWws1dirkvdOacUKGJ8TA";

export const hotelBoards = [
  { initial: "FB", value: "Full Board" },
  { initial: "HB", value: "Half Board" },
  { initial: "BB", value: "Bed & Breakfast" },
  { initial: "AI", value: "All Inclusive" },
  { initial: "RO", value: "Room Only" },
];


export const formatAmount = (amount) => {
  if (amount > 0) {
    return amount.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
  return null;
};
