import React, { useState, useEffect, useContext } from "react";
import Room from "./Room";
import { serverUrl, appKey, formatAmount } from "../Controllers/Config";
import MpesaForm from "../forms/MpesaForm";
import paymentMethods from "./PaymentMehtods";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PersonalInfoStep from "./PersonalInfoStep";
import RoomForm from "../forms/RoomForm";
import { AuthContext } from "../utils/AuthContext";
import AuthForm from "../forms/AuthForm";
import { useParams } from "react-router-dom";
import UpdateGuests from "./UpdateGuests";

const BookingForm = ({
  propertyinfo,
  totalRooms,
  guestData,
  params,
  totalGuests,
  roomGuests,
}) => {
  const { propertyId } = useParams();
  const { isAuthenticated } = useContext(AuthContext);
  const rooms = propertyinfo.propertyData.rooms.filter((room) =>
    propertyinfo.rooms.some((r) => r.roomId === room.id)
  );
  const queryParams = new URLSearchParams(window.location.search);
  const checkIn = queryParams.get("checkIn");
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [numRooms, setNumRooms] = useState(1);
  const [guestsData, setGuestsData] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const [bookRoomsData, setBookRoomsData] = useState([]);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalAmountS, setTotalAmountS] = useState(0);
  const [totalAmountC, setTotalAmountC] = useState("");

  useEffect(() => {
    calculateTotalPayable();
    console.log("bookRoomsData", bookRoomsData);
  }, [bookRoomsData]);

  const handleRoomSelect = (room) => {
    setSelectedRoom(room);
    setCurrentStep(2);
  };

  const handleNextStep = () => {
    if (validateStep()) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleNumRoomsChange = (e) => {
    setNumRooms(e.target.value);
  };

  

  const handlePaymentMethodSelect = (method) => {
    setPaymentMethod(method);
    setCurrentStep(3);
  };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleGuestsDataChange = (data) => {
    // console.log("data:::",data);
    setGuestsData(data);
  };

  const handleSubmit = async () => {
    if (validateStep()) {
      // Extract the lead guest from the first room
      const leadGuest = guestsData[0].guests[0];

      // Prepare room-specific data
      const roomsData = bookRoomsData.map((room, index) => {
        return {
          roomCode: room.roomCode,
          rateCode: room.rateCode,
          expectedPrice: {
            amount: room.expectedPrice.amountMain || 0,
            currency: room.expectedPrice.currencyMain || "KES",
          },
          guests: guestsData[index] ? guestsData[index].guests : [],
          specialRequests: [guestsData[index]?.specialRequests || ""],
        };
      });

      const requestData = {
        totalAmount,
        totalGuests,
        dates: {
          from: params.checkIn,
          to: params.checkOut,
        },
        propertyId: params.propertyId,
        leadGuest: leadGuest,
        rooms: roomsData,
        meta: [],
      };

      const token = localStorage.getItem("hp_jwt");
      try {
        setError(null);
        setLoading(true);
        const response = await fetch(`${serverUrl}/hyperguest/createBooking`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
            APPKEY: `${appKey}`,
          },
          body: JSON.stringify(requestData),
        });

        if (response.status === 200) {
          const data = await response.json();
          setSuccess("Booking successful");
        } else {
          const data = await response.json();
          setError(data.errorDetails.message || "An error occurred.");
        }
      } catch (error) {
        setError(error.message || "An error occurred.");
      } finally {
        setLoading(false);
      }
    }
  };


  const validateStep = () => {
    const errors = {};

    if (currentStep === 1) {
      if (bookRoomsData.length !== totalRooms) {
        toast.error(
          `Please select exactly ${totalRooms} rooms for your booking.`,
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
      }
    }

    if (currentStep === 2) {
      if (!numRooms || numRooms < 1) {
        errors.numRooms = "Number of rooms must be at least 1.";
      }

      
    }

    if (currentStep === 3) {
      if (paymentMethod === "mpesa" && !phoneNumber) {
        errors.phoneNumber = "Please enter your phone number.";
      }
    }

    setValidationErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const calculateTotalPayable = () => {
    let totalPayable = 0;
    let totalPayableS = 0;
    let totalPayableC = "";
    bookRoomsData.forEach((bookroom) => {
      totalPayable += bookroom.expectedPrice.amountMain;
      totalPayableC = bookroom.expectedPrice.currencyMain;
      totalPayableS += bookroom.expectedPrice.amount;
    });
    setTotalAmount(totalPayable);
    setTotalAmountS(totalPayableS);
    setTotalAmountC(totalPayableC);
  };

 const generateCancellationPolicy = (ratePlanData) => {
  console.log("checkIn", checkIn);
   let policyText = "";

   if (ratePlanData && ratePlanData.cancellationPolicies) {
     ratePlanData.cancellationPolicies.forEach((policy) => {
       const {
         daysBefore,
         penaltyType,
         amount,
         timeSetting,
         cancellationDeadlineHour,
         currency,
       } = policy;

       let penaltyDescription = "";

       if (penaltyType === "currency") {
         penaltyDescription = `a penalty of ${currency} ${amount}  will be charged`;
       } else if (penaltyType === "percent") {
         penaltyDescription = `a penalty of ${amount}% of the total amount will be charged`;
         // Check if it's 100% to indicate non-refundable booking
        if (amount === 100) {
          penaltyDescription += " (Non-refundable)";
        }
       } else if (penaltyType === "nights") {
         penaltyDescription = `a penalty of ${amount} night(s) will be charged`;
       }

       if (
         timeSetting &&
         timeSetting.timeFromCheckInType &&
         timeSetting.timeFromCheckIn &&
         cancellationDeadlineHour &&
         checkIn
       ) {
         const { timeFromCheckInType, timeFromCheckIn } = timeSetting;

         // Parse the checkIn date
         let cancellationDate = new Date(checkIn);

         // Check if the date is valid
         if (isNaN(cancellationDate.getTime())) {
           console.error("Invalid checkIn date:", checkIn);
           return;
         }

         console.log("Initial cancellationDate:", cancellationDate);

         // Adjust the cancellation date based on the timeFromCheckInType and timeFromCheckIn
         if (timeFromCheckInType === "hours") {
           cancellationDate.setHours(
             cancellationDate.getHours() - timeFromCheckIn
           );
         } else if (timeFromCheckInType === "days") {
           cancellationDate.setDate(
             cancellationDate.getDate() - timeFromCheckIn
           );
         }

         // Set the hours and minutes based on cancellationDeadlineHour
         const [hours, minutes] = cancellationDeadlineHour
           .split(":")
           .map(Number);
         cancellationDate.setHours(hours);
         cancellationDate.setMinutes(minutes);

         // Format the final cancellation date and time
         const formattedDate = cancellationDate.toLocaleString("en-US", {
           year: "numeric",
           month: "2-digit",
           day: "2-digit",
           hour: "2-digit",
           minute: "2-digit",
           hour12: true,
         });

         console.log(
           "Formatted Date:",
           formattedDate,
           "Raw Date:",
           cancellationDate
         );

         penaltyDescription += ` if cancelled after ${formattedDate}`;
       }

       // Determine whether the policy applies before or after check-in
       const timingText =
         daysBefore > 0
           ? `Cancellation ${daysBefore} days before check-in`
           : `Cancellation ${Math.abs(daysBefore)} days after check-in`;

       policyText += `${timingText}: ${penaltyDescription}. `;
     });
   }

   return policyText;
 };

  return (
    <div className="col-md-12 mt-2">
      <div className="row justify-content-center">
        {/* <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <RoomForm />
            </div>
          </div>
        </div> */}
        <div className="col-md-8 mt-2">
          <div className="card">
            <div className="card-header">
              <h2 className="card-title">Step {currentStep}</h2>
            </div>
            <div className="card-body">
              {currentStep === 1 &&
                (rooms && rooms.length > 0 ? (
                  <div>
                    <h3>Select a Room</h3>
                    <div className="list-group">
                      {rooms.map((room) => (
                        <div
                          key={room.id}
                          className={`list-group-item ${
                            validationErrors.room ? "border-danger" : ""
                          }`}
                        >
                          <div className="row">
                            <div className="col-md-12">
                              <Room
                                room={room}
                                propertyinfo={propertyinfo}
                                totalRooms={totalRooms}
                                bookRoomsData={bookRoomsData}
                                setBookRoomsData={setBookRoomsData}
                                roomGuests={roomGuests}
                                totalGuests={totalGuests}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    {validationErrors.room && (
                      <p className="text-danger mt-2">
                        {validationErrors.room}
                      </p>
                    )}
                  </div>
                ) : (
                  <div className="alert alert-warning">
                    {validationErrors.room
                      ? validationErrors.room
                      : "No Rooms available for the guest selection"}
                  </div>
                ))}

              {currentStep === 2 && (
                <>
                  {isAuthenticated ? (
                    <PersonalInfoStep
                      validationErrors={validationErrors}
                      roomGuests={roomGuests}
                      onGuestsDataChange={handleGuestsDataChange}
                    />
                  ) : (
                    <AuthForm />
                  )}
                </>
              )}

              {currentStep === 3 && (
                <div>
                  <h3>Preferred Payment Mode</h3>
                  <div className="row">
                    <div className="col-md-12">
                      {propertyId === "19912" ? (
                        <div className="alert alert-info">
                          <h4>
                            This is a Cerification Property no payment is
                            required!
                          </h4>
                        </div>
                      ) : (
                        <div className="accordion" id="accordionExample">
                          {paymentMethods.map((method, index) => (
                            <div className="accordion-item" key={index}>
                              <h2
                                className="accordion-header fw-bold"
                                id={`heading${index}`}
                              >
                                <button
                                  className="accordion-button"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target={`#collapse${index}`}
                                  aria-expanded={index === 0 ? "true" : "false"}
                                  aria-controls={`collapse${index}`}
                                >
                                  PAY VIA {method.name}
                                </button>
                              </h2>
                              <div
                                id={`collapse${index}`}
                                className={`accordion-collapse collapse ${
                                  index === 0 ? "show" : ""
                                }`}
                                aria-labelledby={`heading${index}`}
                                data-bs-parent="#accordionExample"
                              >
                                <div className="accordion-body">
                                  <img
                                    src={method.image}
                                    alt={method.label}
                                    style={{ width: "auto", height: "50px" }}
                                  />
                                  {method.component}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-4 mt-2">
          <div className="sticky-top">
            <div className="card">
              <div className="card-body">
                <ul className="list-group">
                  <h3>Pricing Summary</h3>
                  <hr />
                  {bookRoomsData &&
                    bookRoomsData.map((bookroom, index) => (
                      <div key={index}>
                        <p>
                          {bookroom.mydata.room.roomName} -{" "}
                          {bookroom.mydata.plan.name} for{" "}
                          {bookroom.expectedPrice.currencyMain}{" "}
                          {formatAmount(bookroom.expectedPrice.amountMain)}
                          {" (KES "}
                          {formatAmount(bookroom.expectedPrice.amount)}
                          {")"}
                        </p>
                        {/* Taxes Accordion */}
                        <div
                          className="accordion mt-3"
                          id={`taxesAccordion-${index}`}
                        >
                          <div className="accordion-item">
                            <h2
                              className="accordion-header"
                              id={`headingTaxes-${index}`}
                            >
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#collapseTaxes-${index}`}
                                aria-expanded="false"
                                aria-controls={`collapseTaxes-${index}`}
                              >
                                Taxes
                              </button>
                            </h2>
                            <div
                              id={`collapseTaxes-${index}`}
                              className="accordion-collapse collapse"
                              aria-labelledby={`headingTaxes-${index}`}
                              data-bs-parent={`#taxesAccordion-${index}`}
                            >
                              <div className="accordion-body">
                                <ul>
                                  {bookroom.mydata.ratePlanData.prices[
                                    bookroom.mydata.ratePlanData.payment
                                      .chargeType
                                  ]?.taxes.map((tax, taxIndex) => (
                                    <li key={taxIndex} className="text-small">
                                      {tax.description}{" "}
                                      <span className="fw-bold">
                                        {tax.currency}{" "}
                                        {formatAmount(tax.amount)}
                                        {" (KES "}
                                        {formatAmount(tax.searchCurrency)}
                                        {")"}
                                      </span>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Fees Accordion */}
                        <div
                          className="accordion mt-3"
                          id={`feesAccordion-${index}`}
                        >
                          <div className="accordion-item">
                            <h2
                              className="accordion-header"
                              id={`headingFees-${index}`}
                            >
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#collapseFees-${index}`}
                                aria-expanded="false"
                                aria-controls={`collapseFees-${index}`}
                              >
                                Fees
                              </button>
                            </h2>
                            <div
                              id={`collapseFees-${index}`}
                              className="accordion-collapse collapse"
                              aria-labelledby={`headingFees-${index}`}
                              data-bs-parent={`#feesAccordion-${index}`}
                            >
                              <div className="accordion-body">
                                <ul>
                                  {bookroom.mydata.ratePlanData.prices.fees.map(
                                    (fee, feeIndex) => (
                                      <li key={feeIndex} className="text-small">
                                        {fee.description}{" "}
                                        <span className="fw-bold">
                                          {fee.currency}{" "}
                                          {formatAmount(fee.amount)}
                                          {" (KES "}
                                          {formatAmount(fee.searchCurrency)}
                                          {")"}
                                        </span>
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  <hr />
                  <h4 className="fw-bold">
                    Total Amount:
                    <span className="text-primary">
                      {" "}
                      {totalAmountC} {formatAmount(totalAmount)}
                      <br></br>
                      {" (KES "}
                      {formatAmount(totalAmountS)}
                      {")"}
                    </span>
                  </h4>
                  {bookRoomsData && bookRoomsData.length > 0 && (
                    <>
                      <hr />
                      <p className="text-muted text-small">
                        <span className="fw-bold">Cancellation policy</span>
                        <br />
                        {bookRoomsData.map((bookroom, index) => (
                          <React.Fragment key={index}>
                            <span className="fw-bold">
                              {bookroom.mydata.room.roomName} -{" "}
                              {bookroom.mydata.plan.name}
                            </span>
                            <br />
                            <span>
                              {generateCancellationPolicy(
                                bookroom.mydata.ratePlanData
                              )}
                            </span>
                            <br />
                          </React.Fragment>
                        ))}
                      </p>
                    </>
                  )}
                </ul>
              </div>
              <div className="card-footer">
                {success && (
                  <div className="alert alert-success mt-2">{success}</div>
                )}
                {error && (
                  <div className="alert alert-danger mt-2">{error}</div>
                )}
                <div className="d-flex justify-content-between mt-2">
                  {currentStep === 1 && (
                    <UpdateGuests roomGuests={roomGuests} />
                  )}
                  {currentStep > 1 && (
                    <button
                      className="btn btn-secondary"
                      onClick={handlePrevStep}
                    >
                      Previous
                    </button>
                  )}
                  {currentStep < 3 && (
                    <button
                      className="btn btn-primary"
                      onClick={handleNextStep}
                    >
                      Next
                    </button>
                  )}
                  {currentStep === 3 && (
                    <button
                      className="btn btn-success"
                      onClick={handleSubmit}
                      disabled={loading}
                    >
                      {loading ? "Submitting..." : "Submit"}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingForm;
