import React, { useState, useEffect } from "react";
import axios from "axios";
import { serverUrl } from "../Controllers/Config";

const ConfirmPaymentComponent = ({ checkoutRequestID }) => {
  const [alertData, setAlertData] = useState({
    message: "STK Push sent successfully. Processing payment...",
    color: "info",
  });

  const confirmPayment = async () => {
    try {
      const response = await axios.post(`${serverUrl}/mpesa/stkQuery`, {
        CheckoutRequestID: checkoutRequestID,
      });

      const { errorCode, errorMessage, ResultDesc } = response.data;

      if (errorCode === "500.001.1001") {
        setAlertData({
          message: "The transaction is being processed",
          color: "info",
        });
        setTimeout(confirmPayment, 1000);
      } else if (response.data.ResultCode === "0") {
        setAlertData({
          message: "Payment processing complete",
          color: "success",
        });
      } else {
        setAlertData({
          message: `${ResultDesc}`,
          color: "warning",
        });
      }
    } catch (error) {
      console.error("Error confirming payment:", error);
      setAlertData({
        message: "An error occurred while processing the request.",
        color: "danger",
      });
    }
  };

  useEffect(() => {
    confirmPayment();
  }, [checkoutRequestID]); 

  return (
    <div className={`mt-2 alert alert-${alertData.color}`} role="alert">
      {alertData.message}
    </div>
  );
};

export default ConfirmPaymentComponent;
