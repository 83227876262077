import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import DashLayout from "../../components/layout/dashLayout";
import { serverUrl, appKey } from "../../components/Controllers/Config";

const MyBookings = () => {
  const [loading, setLoading] = useState(true);
  const [myBookings, setBookings] = useState([]);

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const token = localStorage.getItem("hp_jwt");
        const response = await axios.get(`${serverUrl}/user/mybookings`, {
          headers: {
            Authorization: `${token}`,
            APPKEY: `${appKey}`,
          },
        });
        const bookingsWithDetails = response.data.map((booking) => {
          const bookingDetails = JSON.parse(booking.b_booking);
          const bookingResponse = JSON.parse(booking.b_response);
          let status = "";
          if (
            bookingResponse &&
            bookingResponse.content &&
            bookingResponse.content.status === "Confirmed"
          ) {
            status = "Successful";
          } else if (bookingResponse && bookingResponse.error) {
            status = `Error: ${bookingResponse.error}`;
            if (
              bookingResponse.errorDetails &&
              Array.isArray(bookingResponse.errorDetails)
            ) {
              bookingResponse.errorDetails.forEach((errorDetail) => {
                status += `<br>${errorDetail.message}`;
              });
            }
          } else {
            status = "Error: Unknown error";
          }
          return {
            ...booking,
            bookingDetails: {
              dates: bookingDetails.dates,
              propertyId: bookingDetails.propertyId,
            },
            status: status,
          };
        });
        setBookings(bookingsWithDetails);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching cancelled booking data:", error);
        setLoading(false);
      }
    };

    fetchBookings();
  }, []);

  const columns = [
    // {
    //   Header: "#",
    //   accessor: "booking_id",
    // },
    {
      Header: "Booking Ref",
      accessor: "b_reference",
    },
    {
      Header: "Booking Details",
      accessor: "bookingDetails",
      Cell: ({ value }) => (
        <div>
          <p>
            Dates: {value.dates.from} to {value.dates.to}
          </p>
          <p>Hotel ID: {value.propertyId}</p>
        </div>
      ),
    },
    {
      Header: "Booking Status",
      accessor: "status",
    },
    // {
    //   Header: "Status",
    //   accessor: "b_status",
    // },
    {
      Header: "Date Booked",
      accessor: "b_datecreated",
    },
    {
      Header: "Action",
      accessor: "b_reference",
      Cell: ({ value }) => (
        <div>
          <a
            href={`/dashboard/mybookings/${value}/view`}
            className="btn btn-primary btn-sm"
            style={{ marginRight: "10px" }}
          >
            View
          </a>
          <a
            href={`/dashboard/mybookings/${value}/cancel`}
            className="btn btn-danger btn-sm"
          >
            Cancel
          </a>
        </div>
      ),
    },
  ];

  return (
    <DashLayout pageTitle="My Bookings">
      <section className="faq-section py-5">
        <div className="container">
          <h4>My Bookings Requests</h4>
          {loading ? (
            <div>Loading...</div>
          ) : (
            <div className="row">
              <div className="col-ms-12 mt-2">
                <div className="card">
                  <div className="card-body">
                    <ReactTable
                      data={myBookings}
                      columns={columns}
                      defaultPageSize={10}
                      className="-striped -highlight"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </DashLayout>
  );
};

export default MyBookings;
