import React, { useState, useContext } from "react";
import axios from "axios";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { useNavigate } from "react-router-dom";
import { serverUrl } from "../Controllers/Config";
import { AuthContext } from "../utils/AuthContext";

const RegisterForm = ({ registerType = "default" }) => {
  const { login } = useContext(AuthContext);
  const titles = ["MR", "MS", "MRS", "C"];
  const [title, setTitle] = useState("");
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [address, setAddress] = useState("");
  const [dob, setDob] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleRegister = async () => {
    try {
      setError(null);
      setLoading(true);

      if (!title) {
        setError("Please select a title");
        return;
      }

      if (!firstname.trim()) {
        setError("Please enter your first name");
        return;
      }

      if (!lastname.trim()) {
        setError("Please enter your last name");
        return;
      }

      if (!email.trim()) {
        setError("Please enter your email address");
        return;
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        setError("Please enter a valid email address");
        return;
      }

      if (!phoneNumber.trim()) {
        setError("Please enter your phone number");
        return;
      }

      if (!password.trim()) {
        setError("Please enter your password");
        return;
      }

      if (password.length < 6) {
        setError("Password must be at least 6 characters long");
        return;
      }

      if (!address.trim()) {
        setError("Please enter your address");
        return;
      }

      if (!dob.trim()) {
        setError("Please enter your date of birth");
        return;
      }

      const requestData = {
        c_title: title,
        c_fname: firstname,
        c_lname: lastname,
        c_email: email,
        c_phone: phoneNumber,
        c_password: password,
        c_address: address,
        c_dob: dob,
      };

      const url =
        registerType === "auth"
          ? `${serverUrl}/auth/register/auth`
          : `${serverUrl}/auth/register`;
      const response = await axios.post(url, requestData);

      if (response.status === 200) {
        console.log("Registration successful:", response.data);
        setError(null);
        if (registerType === "default" || !registerType) {
          navigate("/login?reg=success");
        } else if (registerType === "auth") {
          const { email: userEmail, firstname, jwt, expiry } = response.data;

          localStorage.setItem("hp_email", userEmail);
          localStorage.setItem("hp_firstname", firstname);
          localStorage.setItem("hp_jwt", jwt);
          localStorage.setItem("hp_expiry", expiry);

          console.log("Login successful:", response.data);
          setError(null);

          login();
        }
      } else {
        setError(response.data.message || "An error occurred.");
      }
    } catch (error) {
      console.error(
        "Registration error:",
        error.response?.data?.message || error.message
      );
      setError(error.response?.data?.message || error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form>
      <div className="row">
        <div className="form-group col-md-6 mb-2">
          <label>First Name:</label>
          <input
            type="text"
            className="form-control"
            value={firstname}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
        </div>
        <div className="form-group col-md-6 mb-2">
          <label>Last Name:</label>
          <input
            type="text"
            className="form-control"
            value={lastname}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
        </div>
        <div className="form-group col-md-6 mb-2">
          <label>Select Title:</label>
          <select
            className="form-control"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          >
            <option value="">Select one</option>
            {titles.map((title) => (
              <option key={title} value={title}>
                {title}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group col-md-6 mb-2">
          <label>Date of Birth:</label>
          <input
            type="date"
            className="form-control"
            value={dob}
            onChange={(e) => setDob(e.target.value)}
            required
          />
        </div>
        <div className="form-group col-md-6 mb-2">
          <label>Email Address:</label>
          <input
            type="email"
            className="form-control"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group col-md-6 mb-2">
          <label>Phone Number:</label>
          <PhoneInput
            defaultCountry="ke"
            placeholder="Enter your phone number"
            value={phoneNumber}
            onChange={(value) => setPhoneNumber(value)}
            required
          />
        </div>
        <div className="form-group col-md-6 mb-2">
          <label>Password: <span className="text-small">(min 6 characters)</span></label>
          <input
            type="password"
            className="form-control"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="form-group col-md-6 mb-2">
          <label>Address:</label>
          <input
            type="text"
            className="form-control"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            required
          />
        </div>

        <div className="form-group col-md-12 mb-2">
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleRegister}
            disabled={loading}
          >
            {loading ? "Registering..." : "Register"}
          </button>
          {error && <div className="alert alert-danger mt-2">{error}</div>}
        </div>
      </div>
    </form>
  );
};

export default RegisterForm;
