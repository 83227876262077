import React, { useState } from "react";
import axios from "axios";
import { serverUrl } from "../Controllers/Config";
import {
  PhoneInput,
  defaultCountries,
  parseCountry,
} from "react-international-phone";
import "react-international-phone/style.css";
import ConfirmPaymentComponent from "./ConfirmPaymentComponent ";

const countries = defaultCountries.filter((country) => {
  const { iso2 } = parseCountry(country);
  return ["ke"].includes(iso2);
});

const generateRandomText = () => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  return Array.from({ length: 6 }, () =>
    characters.charAt(Math.floor(Math.random() * characters.length))
  ).join("");
};

const MpesaForm = () => {
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState(null);
  const [checkoutRequestID, setCheckoutRequestID] = useState(null);
  const [alertColor, setAlertColor] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(""); // State for phone number

  const handleSubmit = async () => {
    const phoneNumberRegex = /^\+254\d{9}$/;
    console.log(phoneNumber);
    if (!phoneNumber.match(phoneNumberRegex)) {
      setResponseMessage(
        "Invalid phone number format. Please enter a valid Kenyan phone number."
      );
      setAlertColor("alert-danger");
      return;
    }
    setLoading(true);
    try {

      const response = await axios.post(`${serverUrl}/mpesa/stkPush`, {
        PhoneNo: phoneNumber.replace(/^\+/, ""),
        Amount: "1",
        Reference: generateRandomText(),
      });
      setCheckoutRequestID(response.data.CheckoutRequestID);
    } catch (error) {
      setResponseMessage("An error occurred while processing the request.");
      setAlertColor("alert-danger");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="row mt-2">
      {/* Display response message with dynamic alert color */}
      {responseMessage && (
        <div className={`alert ${alertColor}`} role="alert">
          {responseMessage}
        </div>
      )}

      {/* M-Pesa form */}
      <div className="col-md-12 mb-2">
        <PhoneInput
          defaultCountry="ke"
          placeholder="Enter your phone number"
          value={phoneNumber}
          onChange={(value) => setPhoneNumber(value)}
          countries={countries}
        />
      </div>
      <div className="col-md-12 mb-2">
        <button
          className="btn btn-success"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? "Submitting..." : "Send STK Push"}
        </button>
      </div>
      {/* ConfirmPaymentComponent */}
      {checkoutRequestID && (
        <ConfirmPaymentComponent checkoutRequestID={checkoutRequestID} />
      )}
    </div>
  );
};

export default MpesaForm;
